import { 
  GET_TERRITORIES,
  ADD_TOAST
} from './types';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'

export function getTerritories(eventId) {
  return (dispatch, getState) => { 

    const currentState = getState();
    const data = {
      pagination: {
        page: 0,
        pageSize: 500
      }
    }
    appFetch("POST", API_URL, "/territory/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: GET_TERRITORIES, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })

  }
}