import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import Button from "../Button";
import CodeItem from "./CodeItem";
import ActionModal from "../modals/ActionModal";
import Filter from "../modals/Filter";
import moment from 'moment';
import { getEvents } from '../../actions/events';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import _ from 'lodash';
import {createFilter} from 'react-search-input';

class CodesPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      addEditPopup: false, 
      eventID: -1, 
      copy: false, 
      dropdown: false, 
      filterPopup: false,
      since: moment(), 
      until: moment().add(7, 'days')
    };
    this._bind('openActionPopup', 'hideActionPopup', 'hideFilterPopup', 'showFilterPopup');
    this.codes = [
      {
        name: "VYPE Horeca 2020",
        sum: 500,
        released: 150,
        assigned: 150,
        available: 200,
        groups: [
          {
            name: "Group 1",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 2",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 3",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 4",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 5",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          }
        ]
      },
      {
        name: "GLO Horeca",
        sum: 500,
        released: 150,
        assigned: 150,
        available: 200,
        groups: [
          {
            name: "Group 1",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 2",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 3",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 4",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          },
          {
            name: "Group 5",
            sum: 100,
            released: 30,
            assigned: 30,
            available: 40
          }
        ]
      }
    ]
  }s

  componentWillMount() {
    this.props.getEvents('',{since: moment().format('YYYY-MM-DD'), until: moment().add(7, 'days').format('YYYY-MM-DD')})
  }

  openActionPopup(id, copy) {
    this.setState({addEditPopup: true, eventID: id, copy: copy})
  }

  hideActionPopup() {
    this.setState({addEditPopup: false })
  }

  hideFilterPopup() {
    this.setState({filterPopup: false })
  }

  showFilterPopup() {
    this.setState({filterPopup: true })
  }

  render() {

    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar"  onClick={onClick}/>
    );

    return (
      <section className="codes">
        <div className="page-header actions-header">
          <h1>Kodai</h1>
          <Button type="export" >Importuoti CSV</Button>
          <Button type="export" >Eksportuoti CSV</Button>
        </div>
        <table className={ this.state.addEditPopup ? "codes-table popup-active" : "codes-table" }>
          <thead>
            <tr>
              <td>Nr</td>
              <td>Renginys</td>
              <td>Suma</td>
              <td>Išduota</td>
              <td>Paskirta</td>
              <td>Yra</td>
            </tr>
          </thead>
          <tbody>
            { this.codes.map((item, key) => {
                return (
                  <CodeItem key={'code-'+key} nr={key+1} data={ item } />
                )
              })
            }
          </tbody>
        </table>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, events, user, personnel, } = state
  return { screen, events, user, personnel }
};

export default withRouter(connect(
  mapStateToProps,
  {
    getEvents
  }
)(CodesPage));