export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const SCREEN_DIMENSIONS_SAVE = "SCREEN_DIMENSIONS_SAVE";

export const START_ACTION = "START_ACTION";
export const STOP_ACTION = "STOP_ACTION";
export const REDEEM_CODE = "REDEEM_CODE";
export const UPLOAD_ACTIONS_REQUEST = "UPLOAD_ACTIONS_REQUEST";
export const UPLOAD_ACTIONS = "UPLOAD_ACTIONS";
export const UPLOAD_REPORTS = "UPLOAD_REPORTS";
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const GET_PERSONNEL = "UPLOAD_PERSONNEL";
export const GET_EVENTS = "GET_EVENTS";
export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const RESET_LOCATIONS = "RESET_LOCATIONS";
export const GET_TERRITORIES = "GET_TERRITORIES";
export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_PERSONNEL = "SET_FILTER_PERSONNEL";

export const TIMER_START = "TIMER_START";
export const TIMER_FINISHED = "TIMER_FINISHED";

export const ADD_ACTION = "ADD_ACTION";
export const EDIT_ACTION = "EDIT_ACTION";
export const COPY_ACTION = "COPY_ACTION";
export const SET_MONTH = "SET_MONTH";
export const SET_PERSONNEL_DATE = "SET_PERSONNEL_DATE";
export const SET_DATE_RANGE = "SET_DATE_RANGE";

export const COPY_START = "COPY_START";
export const COPY_UPDATE = "COPY_UPDATE";
export const COPY_END = "COPY_END";

export const QUEUE_EXPORT_ADD_REQUEST = "QUEUE_EXPORT_ADD_REQUEST";
export const QUEUE_EXPORT_ADD = "QUEUE_EXPORT_ADD";
export const QUEUE_DATA = "QUEUE_DATA";
export const QUEUE_FINISHED = "QUEUE_FINISHED";

// routing
export const NAVIGATE_HOME = "NAVIGATE_HOME";

// user
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REMIND = "REMIND";
export const REMIND_OTP = "REMIND_OTP";
export const SESSION = "SESSION";
export const CHANGE_PASS = "CHANGE_PASS";