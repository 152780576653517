export const API_URL = process.env.REACT_APP_API_URL // https://api.tourplanner.tdy-apps.com, https://tourplanner.api.tdy.pl
// export const API_URL = "https://api.tourplanner.lt"

export function appFetch(method, api_url, endpoint, data, token, success, failed) {
    fetch(api_url+endpoint, {
        method: method,
        headers: (token ? {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + token
        } : {
            "Content-Type": "application/json; charset=utf-8"
        }),
        body: method === "GET" ? null : data ? JSON.stringify(data) : null
    })
    .then((response) => {
        console.log(response.headers)
        return response.json()
    })
    .then((result) => {
        success(result)
    }, (error) => {
        failed(error)
    })
}

export function fileFetch(method, api_url, endpoint, data, token, success, failed) {
    fetch(api_url+endpoint, {
        method: method,
        headers: (token ? {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + token
        } : {
            "Content-Type": "application/json; charset=utf-8"
        }),
        body: method === "GET" ? null : data ? JSON.stringify(data) : null
    })
    .then((response) => {
        return response.blob()
    })
    .then((result) => {
        success(result)
    }, (error) => {
        failed(error)
    })
}