// import React, { useState } from "react";
// import { NavLink } from 'react-router-dom'
// import { useHistory } from "react-router-dom";
// import { appFetch } from "./Helpers";
// import BottomModal from './BottomModal';
// import Button from './Button';

// export default function LoginPage() {
//   const [email, setEmail] = useState("");
//   const [passWd, setPassWd] = useState("");
//   const [error, setError] = useState("");
//   const [title, setTitle] = useState("")
//   const [description, setDescription] = useState("")
//   const [warningPopup, setWarningPopup] = useState(false)
//   const history = useHistory();

//   let validate = (e) => {
//     e.preventDefault()

//     if (email === "") {
//       setError("Wpisz adres email")
//     } else if (passWd === "") {
//       setError("Wpisz hasło")
//     } else {
//       setError("")
//       login()
//     }
//   }

//   let login = () => {
//     let data = {
//       username: email,
//       password: passWd
//     }

//     /*if (!window.online) {
//       setTitle("Uwaga!")
//       setDescription("Jesteś offline. Połącz się z Internetem.")
//       setWarningPopup(true)
//     }*/

//     /*appFetch("POST", "/api/login_check", data, (result) => {
//       if (result.token) {
//         sessionStorage.setItem('token', result.token)
//         if (!localStorage.getItem('setpin')) {
//           history.replace("/set-pin")
//         } else {
//           history.replace("/unlock")
//         }
//       } else {
//         setTitle("Uwaga!")
//         setDescription("Niepoprawne dane logowania.")
//         setWarningPopup(true)
//       }
//     }, (error) => {
//       setError("Wystąpił błąd: " + error)
//     })*/
//     history.push("/actions");
//   }

//   const WarningPopup = () => {
//     return (
//       <div className="popup-inner error">
//         <h2>{ title }</h2>
//         <p>{ description }</p>
//         <div className="badge error">
//           <div className="status">!</div>
//           <div className="circle"></div>
//         </div>
//       </div>
//     )
//   }

//   return (
//     <section className="login-page">
//     	<div className="page-inner">
//   			<h2>Zaloguj się</h2>
//   			<p>Podaj e-mail i hasło</p>
//   			<form>
//           <div className="center">
//             <input type="email" placeholder="email" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
//           </div>
//           <div className="center">
//             <input type="password" placeholder="hasło" value={ passWd } onChange={ (e) => { setPassWd(e.target.value) } }/>
//           </div>
//           <div className="remind">
//             Nie pamiętasz hasła? > <NavLink to="/remind">Zresetuj hasło</NavLink>
//           </div>
//           { error !== "" ? <p className="error">{ error }</p> : null }
//           <div className="center">
//             <Button link="/actions" className="btn" >Dalej</Button>
//           </div>
//         </form>
//   		</div>
//       <BottomModal open={ warningPopup } hidePopup={ () => { setWarningPopup(false) } }><WarningPopup/></BottomModal>
//     </section>
//   );
// }

import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { login } from '../actions/user';
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'
import { useHistory } from "react-router-dom";
import Button from './Button'

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Įveskite el.paštą")
    } else if (pass === "") {
      setError("Įveskite slaptažodį")
    } else {
      setError("")
      login()
    }
  }

  let login = () => {
    let data = {
      username: email,
      password: pass
    }
    
    props.login(data, history)
  }

  return (
    <section className="login-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
  			<h2>Prisijungti</h2>
  			<p>Įvesti el.paštą ir slaptažodį</p>
  			<form>
          <div className="center">
            <input type="email" placeholder="El.paštas" name="username" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          <div className="center">
            <input type="password" placeholder="Slaptažodis" name="password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
          </div>
          <div className="remind">
            Pamiršote prisijungimo duomenis? > <NavLink to="/remind">Slaptažodžio atstatymas.</NavLink>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="center">
            <Button className="btn" action={ (e) => { validate(e) } }>Pirmyn</Button>
          </div>
        </form>
  		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    login
  }
)(LoginPage));