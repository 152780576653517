import React, { Component } from 'react';

class LocationView extends Component {

  render() {
    return (
      <div className="location">
        <div className="location-icon"></div>
        <div className="location-data">
          <div className="location-id">{ "ID "+this.props.data.id }</div>
          <div className="location-date">{ this.props.data.address }</div>
          <div className="location-time">{ this.props.data.timestart+" - "+this.props.data.timeend }</div>
        </div>
      </div>
    );
  }
}

export default LocationView;