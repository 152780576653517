import { 
    LOGIN,
    LOGOUT,
    REMIND,
    REMIND_OTP,
    SESSION,
    CHANGE_PASS,
    ADD_TOAST
  } from './types';
import { API_URL, appFetch } from '../helpers/fetch';
import { errors } from '../helpers/errors';
import { uploadActions } from './actions';
import { getPersonnel } from './personnel';
import { getEvents } from './events';
import { clearState } from '../localStorage';
  
export function login(data, history) {
  return (dispatch, getState) => { 
      appFetch("POST", API_URL, "/auth/login", data, false, (result) => {
          if(result.status && result.status.success) {
            if (result.data.user.role.uuid != "HST") {
              dispatch({ type: LOGIN, payload: { token: result.data.token, user: result.data.user } });
              //dispatch(uploadActions())
              dispatch(getEvents())
              dispatch(getPersonnel())
              history.replace("/actions")
              dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Prisijungęs" } });
            } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: "Neturite leidimo" } });
            }
          } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function checkSession(history) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/auth/session", false, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: SESSION, payload: { token: result.data.token, user: result.data.user } });
              //dispatch(uploadActions())
              dispatch(getEvents())
              dispatch(getPersonnel())
              history.replace("/actions")
          } else {
              dispatch({ type: SESSION, payload: { token: null, user: null } });
              //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
              history.replace("/")
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}
  
export function logout(history) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/auth/logout", false, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
          if(result.status && result.status.success) {
              clearState()
              dispatch({ type: LOGOUT, payload: null });
              history.replace("/")
          } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
              history.replace("/")
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function remind(data, history) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/reset-token", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REMIND, payload: data.username });
          history.replace("/remind/code")
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          history.replace("/remind")
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function changePass(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/update-password", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: CHANGE_PASS, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Slaptažodis pakeistas." } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function remindCode(data, history) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/reset-password", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REMIND_OTP, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Slaptažodis pakeistas sėkmingai!!" } });
          history.replace("/")
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          history.replace("/remind")
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}
