import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Switch,
  Route
} from "react-router-dom";
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import Header from "../components/Header"
import { saveDimensionData } from '../actions/screen';
import { withRouter } from 'react-router-dom';
import Toasts from '../components/toasts/Toasts';
import LoginPage from '../components/LoginPage';
import ActionsPage from '../components/actions/ActionsPage';
import ReportsPage from '../components/reports/ReportsPage';
import PersonnelPage from '../components/personnel/PersonnelPage';
import RemindPage from '../components/RemindPage';
import RemindCodePage from '../components/RemindCodePage';
import EventsPage from '../components/events/EventsPage';
import CodesPage from '../components/codes/CodesPage';
import UserDataPage from '../components/UserDataPage';
import { ReactComponent as IconMenu } from '../../img/menu.svg'
import Navigation from '../components/Navigation';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'
import { checkSession } from '../actions/user';

class App extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { navOpen: false };
    //this._bind('validate');
  }

  componentWillMount() {
    //console.log('////////////////////////////////////// MOUNTING APP ////////////////////////////////////////')
    this.updateDimensions();
    this.props.checkSession(this.props.history)
  }

  componentDidMount() {
    //console.log('////////////////////////////////////// APP MOUNTED ////////////////////////////////////////')
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.props.saveDimensionData({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  render() {

    return (
      <>
      <div className="app">
        <Header />
        <Logo className="bat-logo"/>
        { this.props.location.pathname != '/' ? <div className="nav-trigger" onClick={ ()=>{ this.setState({ navOpen: true }) }}><IconMenu /></div> : null }
        <Navigation open={this.state.navOpen} close={ ()=>{ this.setState({ navOpen: false }) }} />

        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} exact>
            <LoginPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/actions`} exact>
            <ActionsPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/reports`} exact>
            <ReportsPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/personnel`} exact>
            <PersonnelPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/remind`} exact>
            <RemindPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/remind/code`} exact>
            <RemindCodePage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/events`} exact>
            <EventsPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/locations`} exact>
            <EventsPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/regions`} exact>
            <EventsPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/kody`} exact>
            <CodesPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/twoje-konto`} exact>
            <UserDataPage/>
          </Route>
        </Switch>
        
        <Toasts/>
        <div className="page-preloader"><span></span></div>
      </div>
    </>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen } = state
  return { screen }
};

export default withRouter(connect(
  mapStateToProps,
  {
    saveDimensionData,
    checkSession
  }
)(App));