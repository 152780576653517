import { combineReducers } from 'redux'
import actionsReducer from "../reducers/actions";
import reportsReducer from "../reducers/reports";
import personnelReducer from "../reducers/personnel";
import toastsReducer from "../reducers/toasts";
import screenReducer from "../reducers/screen";
import userReducer from "../reducers/user";
import eventsReducer from "../reducers/events";
import locationsReducer from "../reducers/locations";
import territoriesReducer from "../reducers/territories";

export default (history) => combineReducers({
  actions: actionsReducer,
  reports: reportsReducer,
  personnel: personnelReducer,
  toasts: toastsReducer,
  screen: screenReducer,
  user: userReducer,
  events: eventsReducer,
  locations: locationsReducer,
  territories: territoriesReducer
})