import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import Button from "../Button";
import { statusChange } from '../../actions/actions';
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import moment from 'moment';
import { addToast } from '../../actions/toast';

class CodeItem extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { showGroups: false };
  }

  render() {
    const data = this.props.data;

    return (
      <>
        <tr className="action-row codes-row">
          <td className="action-nr" rowspan={this.state.showGroups ? this.props.data.groups.length+1 : 1}><span>{ this.props.nr }</span></td>
          <td className="codes-name" style={{ width: "30%"}} onClick={ ()=>{ this.setState({ showGroups: !this.state.showGroups }) }}>
            <span>{ data.name }</span>
          </td>
          <td>{ data.sum }</td>
          <td>{ data.released }</td>
          <td>{ data.assigned }</td>
          <td>{ data.available }</td>
        </tr>
        { this.props.data.groups.map(( group, key)=>{
            return (
              <tr className={ this.state.showGroups ? "action-row group-row active" : "action-row group-row"}>
                <td>
                  <span>{ group.name }</span>
                </td>
                <td>{ group.sum }</td>
                <td>{ group.released }</td>
                <td>{ group.assigned }</td>
                <td>{ group.available }</td>
              </tr>
            )
          })
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    statusChange,
    addToast
  }
)(CodeItem));