import React, { Component } from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'

export default class extends Component {

  constructor(props) {
    super(props)
    Modal.setAppElement('#root')
  }

  handleSwipe(e) {
    if (e.direction === 16) {
      this.props.hidePopup()
    }
  }

  render() {

    return (
        <Modal
          isOpen={ this.props.open } 
          contentLabel="Modal" 
          className={{
            base: 'bottom-modal',
            afterOpen: 'bottom-modal_after-open',
            beforeClose: 'bottom-modal_before-close'
          }}
          overlayClassName={{
            base: 'bottom-overlay',
            afterOpen: 'bottom-overlay_after-open',
            beforeClose: 'bottom-overlay_before-close'
          }} 
          onRequestClose={ () => { this.props.hidePopup() } } 
          closeTimeoutMS={ 1000 }
        >
          <Hammer direction="DIRECTION_VERTICAL" onSwipe={ (e) => { this.handleSwipe(e) } }>
            <div className="swipe-close">
              { this.props.children }
              <button className="btn-close" onClick={ (e) => { this.props.hidePopup() } }><div className="icon"></div><p>Uždaryti</p></button>
            </div>
          </Hammer>
        </Modal>
    )
  }
}
