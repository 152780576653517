import { 
  UPLOAD_REPORTS,
  ADD_TOAST,
  DOWNLOAD_REPORT
} from './types';
import { API_URL, appFetch } from '../helpers/fetch';
import { errors } from '../helpers/errors';

export function uploadReports(since, until, page) {
  return (dispatch, getState) => { 
    const currentState = getState()

    const data = {
      "actions": {
        "since": since,
        "until": until,
        "finished": true
      },
      "pagination": {
        "limit": 100,
        "offset": page*100||0
      }
    }
    appFetch("POST", API_URL, "/action/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: UPLOAD_REPORTS, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function downloadReport(since, until) {
  return (dispatch, getState) => { 
    const currentState = getState()

    appFetch("POST", API_URL, "/action/report/salesforce/"+since+"/"+until, {},currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: DOWNLOAD_REPORT, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}