import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import Button from "../Button";
import { statusChange } from '../../actions/actions';
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import moment from 'moment';
import { addToast } from '../../actions/toast';

class EventItem extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { statusSelect: false };
  }

  /*changeStatus(action, newStatus) {
    if (this.checkFields(action)) {
      this.props.statusChange({ 
        status: { 
          ident: newStatus
        }, 
        action: {
          uuid: action.uuid
        }
      }, this.props.range)
    } else {
      this.props.addToast({ type: "error", message: "Uzupełnij brakujące dane" });
    }
  }

  checkFields(action) {
    if (action.event != null && action.users != null && action.actionPoints != null && action.since != null && action.until != null) {
      return true;
    } else {
      return false;
    }
  }*/

  render() {
    const event = this.props.event;
    let editable = false;
    /*if (event.status.global.ident != 'cancelled' && event.status.global.ident != 'started' && event.status.global.ident != 'finished') {
      editable = true
    }
    const icons = {
      'started': <ActiveIcon />,
      'editable': <NewIcon />,
      'cancelled': <CanceledIcon />,
      'finished': <EndedIcon />,
      'accepted': <ApprovedIcon />
    }*/

    return (
      <tr className={ "action-row " }>
        <td className="action-nr"><span>{ this.props.nr }</span></td>
        <td className="action-name"><span>{ event.name }</span></td>
        <td>{ event.ident }</td>
        <td>{ event.since != null ? moment(event.since.date).format('D/M/Y') : '' }</td>
        <td>{ event.until != null ? moment(event.until.date).format('D/M/Y') : '' }</td>
        <td>
        { event.territory? event.territory.map((item, key) => {
            return <p key={ "item-"+key }>{ item.ident }</p>
          }) : null }
        </td>
        <td>
          <div className="buttons">
            <Button event={ ()=>{this.props.openPopup(event.ident)} }>Redaguoti</Button>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, events, user } = state
  return { screen, events, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    statusChange,
    addToast
  }
)(EventItem));