import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Route, Switch } from 'react-router' // react-router v4
import { BrowserRouter } from 'react-router-dom'
import configureStore, { history } from './app/store'
import { loadState } from './app/localStorage';
//import * as serviceWorker from './serviceWorker';
import App from './app/containers/App';
import Page from './app/containers/Page';
import data from './app/data/data.json';
import './scss/style.scss';

//serviceWorker.unregister();

const preloadedState = loadState();
console.log(preloadedState);
const store = configureStore(preloadedState)


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>, 
  document.getElementById('root'));