import { 
  GET_LOCATIONS,
  RESET_LOCATIONS,
  ADD_TOAST
} from './types';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'

export function getLocations(eventId, territoryId) {
  return (dispatch, getState) => { 

    const currentState = getState();
    const data = {
      pagination: {
        page: 0,
        pageSize: 500
      },
      event: {
        uuid: eventId
      },
      territory: {
        uuid: territoryId || currentState.user.user.territories[0].uuid
      }
    }
    appFetch("POST", API_URL, "/point/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: GET_LOCATIONS, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })

  }
}

export function resetLocations() {
  return (dispatch, getState) => { 
    dispatch({ type: RESET_LOCATIONS, payload: '' });
  }
}
