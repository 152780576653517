import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { changePass } from '../actions/user';
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import BottomModal from './modals/BottomModal';
import Button from './Button';
import LocationView from './LocationView';
import PopupModal from './modals/PopupModal';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg'

class UserDataPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { passwordPopup: false, passWD: "" };
  }

  changePass() {
      const data = {
          user: {
              password: this.state.passWD
          }
      }

      this.props.changePass(data)
      this.setState({ passwordPopup: false, passWD: "" })
}

  render() {
      const user = this.props.user.user || {}

    return (
      <section className="user-data">
        <NavLink to="/actions" className="back"><Arrow/></NavLink>
        <h1 className="title">Vartotojo duomenys</h1>
        <div className="user-data-content">
            <h3>ID</h3>
            <p>{ user.ident }</p>
            <h3>Prisijungimas</h3>
            <p>{ user.username }</p>
            <h3>Vardas ir Pavardė</h3>
            <p>{ user.firstname + " " + user.lastname }</p>
            <h3>Priskirtos teritorijos</h3>
            <ul className="territories">
                { user.territories?.map ((item, i) => {
                    return <li key={ i }>{ item.ident }</li>
                }) }
            </ul>
            <h3>El.pašto duomenys</h3>
            <p>{ user.emailAddress }</p>
            <h3>Telefono numeris</h3>
            <p>{ user.mobilePhone }</p>
            <h3>Slaptažodis</h3>
            <p className="pass"><span>********</span><button className="btn" onClick={ () => { this.setState({ passwordPopup: true }) } }>Pakeisti</button></p>
        </div>
        <PopupModal open={ this.state.passwordPopup } hidePopup={ ()=>{ this.setState({ passwordPopup: false }) } }>
          <div className="popup-inner">
            <h3>Slaptažodžio keitimas</h3>
            <input type="password" placeholder="Įveskite naują slaptažodį" value={ this.state.passWD } onChange={ (e) => { this.setState({ passWD: e.target.value }) } }/>
            <button className="btn" onClick={ () => { this.changePass() } }>Pakeisti slaptažodį</button>
          </div>
        </PopupModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    changePass
  }
)(UserDataPage));