import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ActionsIcon } from '../../img/actions.svg'
import { ReactComponent as CalendarIcon } from '../../img/calendar.svg'
import { ReactComponent as ReportIcon } from '../../img/report-icon.svg'

export default function BottomNav() {
  return (
    <div className="bottom-nav">
    	<ul>
    		<li>
    			<NavLink to="/akcje">
    				<div className="icon"><ActionsIcon/></div>
    				<span>AKTYVACIJOS</span>
    			</NavLink>
    		</li>
    		<li>
    			<NavLink to="/kalendarz">
    				<div className="icon"><CalendarIcon/></div>
    				<span>Kalendorius</span>
    			</NavLink>
    		</li>
    		<li>
    			<NavLink to="/raporty">
    				<div className="icon"><ReportIcon/></div>
    				<span>Ataskaitos</span>
    			</NavLink>
    		</li>
    	</ul>
    </div>
  );
}
