import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { addAction, editAction, copyAction } from '../../actions/actions';
import BaseComponent from "../Helpers";
import Button from "../Button";
import _ from 'lodash';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as CheckboxMarkedIcon } from '../../../img/checkbox-marked.svg';
import { ReactComponent as CheckboxIcon } from '../../../img/checkbox.svg';
import moment from 'moment';

class CopyAction extends BaseComponent {

  constructor(props) {
    super(props)
    let action = _.find(this.props.actions.list, [ 'ident', this.props.ident ]);
    if (action === undefined) {
      action = this.props.actions.default;
    }
    this.state = { 
      action: action,
      copySelected: [true,true,true,true,true,true],
      copyVol: 1
    };
    this._bind('copyAction');
  }

  toggleCopy(index) {
    const newValue = !this.state.copySelected[index];
    console.log([...this.state.copySelected.slice(0, index), newValue, ...this.state.copySelected.slice(index+1)])
    this.setState({ copySelected: [...this.state.copySelected.slice(0, index), newValue, ...this.state.copySelected.slice(index+1)] })
  }

  copyItem(action, copy) {
    for (let i = 0; i < this.state.copyVol; i++) {
      this.props.copyAction(action, copy)
    }
  }

  copyAction() {
    let action = {...this.state.action};
    if (!this.state.copySelected[0]) {
      action.name = '';
    }
    if (!this.state.copySelected[2]) {
      action.actionPoints = [];
    }
    if (!this.state.copySelected[3] || !this.state.copySelected[4]) {
      action.since = null;
      action.until = null;
    }
    if (!this.state.copySelected[5]) {
      action.users = [];
    }
    this.props.copyAction(action, this.state.copyVol, this.props.range)
  }

  render() {
    const action = this.state.action;
    console.log(action)

    return (
      <div className="add-edit-holder">
        <table className="add-edit-table">
          <tbody>
            <tr className={ "action-row new" }>
              <td className="status-cell"><div className={ "status-icon" }><NewIcon/></div></td>
              <td onClick={ ()=>{this.setState({activeInput: "actionName"})}} className="action-name">
                <span>{ action.name === '' ? 'Aktyvacijos pavadinimas' : action.name }</span>
                <div onClick={ ()=>{ this.toggleCopy(0) } } className={ this.state.copySelected[0] ? "copy-select" : "copy-select active" }>{ this.state.copySelected[0] ? <CheckboxMarkedIcon/> : <CheckboxIcon /> }</div>
              </td>
              <td>{ action.ident }</td>
              <td onClick={ ()=>{this.setState({activeInput: "eventName"})}}>
                { action.event.name === '' ? 'Veiklos pavadinimas' : action.event.name }
                {/*<div onClick={ ()=>{ this.toggleCopy(1) } } className={ this.state.copySelected[1] ? "copy-select" : "copy-select active" }>{ this.state.copySelected[1] ? <CheckboxMarkedIcon/> : <CheckboxIcon /> }</div>*/}
              </td>
              <td onClick={ ()=>{this.setState({activeInput: "location"})}}>
                { action.actionPoints ? action.actionPoints.length > 0 ? 
                  action.actionPoints.map((item, key) => {
                    return <p key={key}>{ item.name+', '+item.address.streetAddress+' '+item.address.streetNumber+', '+item.address.cityName+', ID: '+item.ident }</p>
                  }) : 'Lokacja'
                  : 'Lokacja'
                }
                <div onClick={ ()=>{ this.toggleCopy(2) } } className={ this.state.copySelected[2] ? "copy-select" : "copy-select active" }>{ this.state.copySelected[2] ? <CheckboxMarkedIcon/> : <CheckboxIcon /> }</div>
              </td>
              <td onClick={ ()=>{this.setState({activeInput: "date"})}}>{ action.since ? moment(action.since.date).format("D/M/Y") : "Data" }
              <div onClick={ ()=>{ this.toggleCopy(3) } } className={ this.state.copySelected[3] ? "copy-select" : "copy-select active" }>{ this.state.copySelected[3] ? <CheckboxMarkedIcon/> : <CheckboxIcon /> }</div>
              </td>
              <td onClick={ ()=>{this.setState({activeInput: "time"})}}>{ action.since && action.until ? moment(action.since.date).format("H:mm")+' - '+moment(action.until.date).format("H:mm") : "Kalendorius" }
                <div onClick={ ()=>{ this.toggleCopy(4) } } className={ this.state.copySelected[4] ? "copy-select" : "copy-select active" }>{ this.state.copySelected[4] ? <CheckboxMarkedIcon/> : <CheckboxIcon /> }</div>
              </td>
              <td onClick={ ()=>{this.setState({activeInput: "personnel"})}}>
                { action.users ? action.users.length > 0 ?
                  action.users.map((item, key) => {
                    return <p key={ key }>{ item.firstname+' '+item.lastname }</p>
                  }) : 'Darbuotojas'
                : 'Darbuotojas'}
                <div onClick={ ()=>{ this.toggleCopy(5) } } className={ this.state.copySelected[5] ? "copy-select" : "copy-select active" }>{ this.state.copySelected[5] ? <CheckboxMarkedIcon/> : <CheckboxIcon /> }</div>
              </td>
              <td>
                <div className="buttons">
                  <div>
                    <p>Kopijų skaičius</p>
                    <input style={{padding: "7px 15px", height: "10px", marginTop: "5px"}} type="number" min="1" max="100" value={ this.state.copyVol } onChange={ (e) => { this.setState({ copyVol: e.target.value }) } }/>
                  </div>
                  <Button type="small"
                    action={ this.copyAction }>Kopijuoti</Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, personnel } = state
  return { screen, actions, personnel }
};

export default withRouter(connect(
  mapStateToProps,
  {
    addAction,
    editAction,
    copyAction
  }
)(CopyAction));