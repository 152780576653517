import React, { Component } from 'react';
import { connect } from "react-redux";
import BottomNav from "../components/BottomNav";

class Page extends Component {

  componentWillMount() {

  }

  render() {
    let pageClass = this.props.children.props.location.pathname.substr(1)+"-page";

    if (pageClass.indexOf('/') > -1) {
      pageClass = pageClass.substr(0, pageClass.indexOf('/') - 1)+"-page"
    }

    if (pageClass === '-page') {
      pageClass = 'welcome-page';
    }

    const hideFooter = this.props.children.props.hideFooter;

    return (
      <div className={"page "+pageClass} >
        {this.props.children}
        { hideFooter ? null : <BottomNav/> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen } = state
  return { screen }
};

export default connect(
  mapStateToProps,
  {

  }
)(Page);