import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { addEvent, editEvent } from '../../actions/events';
import BaseComponent from "../Helpers";
import Button from "../Button";
import _, { map } from 'lodash';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg'
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import SearchInput, {createFilter} from 'react-search-input'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import lt from 'date-fns/locale/lt';
import { setDate, getPersonnel } from '../../actions/personnel';
import { getTerritories } from '../../actions/territories';
registerLocale('lt', lt)

class AddEditEvent extends BaseComponent {

  constructor(props) {
    super(props)
    let event = _.find(this.props.events.list, [ 'ident', this.props.ident ]);
    if (event === undefined) {
      event = {
        new: true, 
        ident: "event-"+moment().format("DD.MM.YYYY"), 
        name: "",
        chain: "",
        territory: []
      };
    }

    this.state = { 
      event: event,
      activeInput: "eventName", // eventName, eventIdent, eventChain
      selectedTerritories: [],
      territoryFilter: '',
      day: event.since ? moment(event.since.date).format('D') : '',
      month: event.since ? moment(event.since.date).format('M') : '',
      year: event.since ? moment(event.since.date).format('Y') : '',
      timestart: event.since ? moment(event.since.date).format('HH:mm') : '',
      timeend: event.until ? moment(event.until.date).format('HH:mm') : ''
    };
    this._bind('handleDateChange', 'handleTimeChange')
  }

  componentDidMount() {
    this.props.getTerritories();
  }

  handleInputChange(e) {
    const target = e.target
    let value = target.type === 'checkbox' ? target.checked : target.value

    this.setState({
      event: { ...this.state.event, name: value, ident: 'event-'+value+'-'+moment().format("DD.MM.YYYY") }
    })
  }

  handleDateChange(e) {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let day = this.state.day;
    let month = this.state.month;
    let year = this.state.year;
    if (name === 'day') day = value;
    if (name === 'month') month = value;
    if (name === 'year') year = value;
    if (moment(month+'.'+day+'.'+year).isValid()) {
      this.setState({
        day: day,
        month: month,
        year: year,
        event: { ...this.state.event, since: { date: moment(month+'.'+day+'.'+year+' '+this.state.timestart).format("YYYY-MM-DD HH:mm:ss") }, until: { date: moment(month+'.'+day+'.'+year+' '+this.state.timeend).format("YYYY-MM-DD HH:mm:ss") } }
      })
      this.props.setDate( month+'.'+day+'.'+year )
    } else {
      this.setState({
        day: day,
        month: month,
        year: year
      })
    }
  }

  handleTimeChange(e) {
    const target = e.target;
    let value = target.value;
    let formatedValue = value;
    let name = target.name;
    if (value.indexOf(':') === -1 ) {
      formatedValue += ':00';
    }
    if (name === 'timestart') {
      this.setState({
        event: { ...this.state.event, since: { date: moment(this.state.year+'-'+this.state.month+'-'+this.state.day+' '+formatedValue).format("YYYY-MM-DD HH:mm:ss") } },
        timestart: value
      })
    } else {
      this.setState({
        event: { ...this.state.event, until: { date: moment(this.state.year+'-'+this.state.month+'-'+this.state.day+' '+formatedValue).format("YYYY-MM-DD HH:mm:ss") } },
        timeend: value
      })
    }
  }

  selectTerritory(territory, index) {
    let territories =  this.state.event.territory
    if (this.state.event.territory && this.state.event.territory.length > 0) {
      let exists = false; 
      _.each(territories, (item) => {
        if ( territory.uuid === item.uuid ) {
          item.trash = !item.trash;
          exists = true;
        } else {
          item.trash = true;
        }
      })
      if(!exists) {
        territories.push({ trash: false, uuid: territory.uuid, ident: territory.ident})
      }
    } else {
      territories = [{ trash: false, uuid: territory.uuid, ident: territory.ident}];
    }

    this.setState({
      action: { ...this.state.action, territory: territories }
    })
  }

  render() {
    const event = this.state.event;
    let inputBox = null;

    const icons = {
      'active': <ActiveIcon />,
      'new': <NewIcon />,
      'canceled': <CanceledIcon />,
      'ended': <EndedIcon />,
      'accepted': <ApprovedIcon />
    }

    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar"  onClick={onClick}/>
    );

    switch(this.state.activeInput) {
      case "eventName":
        inputBox = (
          <div className="input-box input-box-name">
            <h3>Įvesti veiklos pavadinimą</h3>
            <input type="text" name="name" value={ event.name } onChange={ (e)=>{ this.handleInputChange(e) } } placeholder="Nazwa eventu" />
            <h3>Paskutiniai naudoti vardai</h3>
            <div className="buttons">
              <Button action={ ()=>{ this.setState({activeInput: 'dateStart'}) } }>Kitas</Button>
            </div>
          </div>
        )
        break;
      case "dateStart":
        const date = this.state.day ? moment(this.state.day + "-" + this.state.month + "-" + this.state.year, "DD-MM-YYYY").toDate() : moment().toDate()
        inputBox = (
          <div className="input-box input-box-date">
            <h3>Pasirinkti data</h3>
            <div className="date-inputs">
              <input onChange={ this.handleDateChange } value={this.state.day} name="day" placeholder="diena" />
              <input onChange={ this.handleDateChange } value={this.state.month} name="month" placeholder="mėnuo" />
              <input onChange={ this.handleDateChange } value={this.state.year} name="year" placeholder="metai" />
              <DatePicker
              selected={date}
              locale="lt"
              onChange={ (date) => {
                let dt = moment(date);
                this.setState({
                  day: dt.format("DD"),
                  month: dt.format("MM"),
                  year: dt.format("YYYY"),
                  event: { ...this.state.event, since: { date: moment(dt.format("YYYY-MM-DD")+' '+this.state.timestart).format("YYYY-MM-DD HH:mm:ss") } }
                })
                this.props.setDate( dt.format("YYYY-MM-DD") )
              } }
              customInput={<CalendarInput/>}
            />
            </div>
            <div className="buttons">
            <Button action={ ()=>{ this.setState({activeInput: 'dateEnd'}) } }>Kitas</Button>
            </div>
          </div>
        )
        break;
      case "dateEnd":
        const dateEnd = this.state.day ? moment(this.state.day + "-" + this.state.month + "-" + this.state.year, "DD-MM-YYYY").toDate() : moment().toDate()
        inputBox = (
          <div className="input-box input-box-date">
            <h3>Pasirinkti data</h3>
            <div className="date-inputs">
              <input onChange={ this.handleDateChange } value={this.state.day} name="day" placeholder="dieną" />
              <input onChange={ this.handleDateChange } value={this.state.month} name="month" placeholder="mėnuo" />
              <input onChange={ this.handleDateChange } value={this.state.year} name="year" placeholder="metų" />
              <DatePicker
              selected={dateEnd}
              locale="lt"
              onChange={ (dateEnd) => {
                let dt = moment(dateEnd);
                this.setState({
                  day: dt.format("DD"),
                  month: dt.format("MM"),
                  year: dt.format("YYYY"),
                  event: { ...this.state.event, until: { date: moment(dt.format("YYYY-MM-DD")+' '+this.state.timestart).format("YYYY-MM-DD HH:mm:ss") } }
                })
                this.props.setDate( dt.format("YYYY-MM-DD") )
              } }
              customInput={<CalendarInput/>}
            />
            </div>
            <div className="buttons">
            <Button action={ ()=>{ this.setState({activeInput: 'territories'}) } }>Kitas</Button>
            </div>
          </div>
        )
        break;
      case "territory":
        const territories = []
        const filteredTerritories = this.props.territories.list.filter(createFilter(this.state.territoryFilter, ['ident']))

        inputBox = (
          <div className="input-box input-box-territory">
            <h3>Pasirinkite iš sąrašo</h3>
            <SearchInput className="search-input" placeholder="Teritorijos paieška" onChange={(filter) => {
              this.setState({ territoryFilter: filter })
            }} />
            <ul>
              { filteredTerritories.map((item, i) => {
                return <li key={ i } className={ '' } onClick={ ()=>{ this.selectTerritory(item, i) } }>{ item.ident }</li>
              }) }
            </ul>
            <Button 
              action={ ()=>{ 
                this.state.event.new ? 
                  this.props.addEvent(this.state.event) :
                  this.props.editEvent(this.state.event);
                this.props.hide(); } 
              }>Išsaugoti</Button>
          </div>
        )
        break;
      default:
        break;
    }

    return (
      <div className="add-edit-holder">
        <table className="add-edit-table">
          <tbody>
            <tr className={ "action-row new" }>
              <td></td>
              <td onClick={ ()=>{this.setState({activeInput: "eventName"})}} className="action-name"><span>{ event.name === '' ? 'Veiklos pavadinimas' : event.name }</span></td>
              <td>{ event.ident }</td>
              <td onClick={ ()=>{this.setState({activeInput: "dateStart"})}}>{ event.since ? moment(event.since.date).format("D/M/Y") : "Pradžios data" }</td>
              <td onClick={ ()=>{this.setState({activeInput: "dateStart"})}}>{ event.until ? moment(event.until.date).format("D/M/Y") : "Pabaigos data" }</td>
              <td onClick={ ()=>{this.setState({activeInput: "territory"})}}>
                { event.territory ? event.territory.length > 0 ?
                  event.territory.map((item, key) => {
                    if (!item.trash) {
                      return <p key={ key }>{ item.ident }</p>
                    }
                  }) : 'Teritorija'
                : 'Teritorija'}
              </td>
              <td>
                <div className="buttons">
                  <Button 
                    action={ ()=>{ 
                      this.state.event.new ? 
                        this.props.addEvent(this.state.event) :
                        this.props.editEvent(this.state.event);
                      this.props.hide(); } 
                    }>Išsaugoti</Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        { inputBox }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, personnel, user, events, territories } = state
  return { screen, personnel, user, events, territories }
};

export default withRouter(connect(
  mapStateToProps,
  {
    setDate,
    addEvent,
    editEvent,
    getTerritories
  }
)(AddEditEvent));