import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../components/Helpers";
import { NavLink } from 'react-router-dom';
import { logout } from '../actions/user';
import { ReactComponent as IconEvents } from '../../img/menu-eventy.svg'
import { ReactComponent as IconActions } from '../../img/menu-akcje.svg'
import { ReactComponent as IconReports } from '../../img/menu-raporty.svg'
import { ReactComponent as IconPersonnel } from '../../img/menu-personel.svg'
import { ReactComponent as IconAvatar } from '../../img/menu-avatar.svg'
import { ReactComponent as IconClose } from '../../img/menu-close.svg'
import { ReactComponent as IconAccount } from '../../img/menu-twoje-konto.svg'
import { ReactComponent as IconLogout } from '../../img/menu-wyloguj.svg'
import { ReactComponent as IconRegions } from '../../img/menu-regionalizacja.svg'
import { ReactComponent as IconKody } from '../../img/menu-kody.svg'

class Navigation extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { };
  }

  render() {
    
    return (
      <div className={this.props.open ? "nav active" : "nav" }>
        <div className="close" onClick={ this.props.close }><IconClose /></div>
        <div className="profile-section">
          {/*<IconAvatar className="avatar" />*/}
          <p>Labas<br/><span className="profile-name">{ this.props.user.user ? this.props.user.user.firstname : "" }</span></p>
        </div>
        { this.props.user.user && this.props.user.user.role.uuid === "HSADM" ?
          <div className="nav-links">
            <NavLink className="nav-item" exact to="/actions" onClick={ this.props.close }><IconActions /><span>Aktyvacijos</span></NavLink>
            <NavLink className="nav-item" exact to="/reports" onClick={ this.props.close }><IconReports /><span>Ataskaitos</span></NavLink>
          </div>
        : <div className="nav-links">
          { this.props.user.user && this.props.user.user.role.uuid === "ADMIN" ?
            <NavLink className="nav-item" exact to="/events" onClick={ this.props.close }><IconEvents /><span>Eventy</span></NavLink> : null }
            <NavLink className="nav-item" exact to="/actions" onClick={ this.props.close }><IconActions /><span>Aktyvacijos</span></NavLink>
            <NavLink className="nav-item" exact to="/reports" onClick={ this.props.close }><IconReports /><span>Ataskaitos</span></NavLink>
            <NavLink className="nav-item" exact to="/personnel" onClick={ this.props.close }><IconPersonnel /><span>Komanda</span></NavLink>
          { this.props.user.user && this.props.user.user.role.uuid === "ADMIN" ?
            <>
              <NavLink className="nav-item" exact to="/regions" onClick={ this.props.close }><IconRegions /><span>Regionalizacja</span></NavLink> 
              <NavLink className="nav-item" exact to="/kody" onClick={ this.props.close }><IconKody /><span>Kodai</span></NavLink>
            </> : null } 
          </div> }
        <div className="nav-footer">
          <NavLink className="nav-item" exact to="/twoje-konto" onClick={ this.props.close }><IconAccount /><span>Vartotojo duomenys</span></NavLink>
          <span className="nav-item" onClick={ () => { this.props.close(); this.props.logout(this.props.history) } }><IconLogout /><span>Išjungti</span></span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    logout
  }
)(Navigation));