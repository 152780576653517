import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg';
import { remindCode } from '../actions/user';

function RemindCodePage(props) {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");

  let validate = (e) => {
    e.preventDefault()

    if (pass === "") {
      setError("Iveskite nauja slaptazodi")
    } else if (code === "") {
      setError("Įveskite kodą")
    } else {
      setError("")
      props.remindCode({username: props.user.remindEmail, otp: code, password: pass}, history)
    }
  }

  return (
    <section className="remind-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
			<h2>Naujas slaptažodis</h2>
			<p>Įveskite gautą kodą ir naują slaptažodį</p>
			<form autocomplete="off">
        <div className="center">
            <input type="text" placeholder="kodas" name="otp" autocomplete="otp" value={ code } onChange={ (e) => { setCode(e.target.value) } }/>
        </div>
        <div className="center">
            <input type="password" placeholder="naujas slaptažodis" name="newpass" autocomplete="new-password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
        </div>
        { error !== "" ? <p className="error">{ error }</p> : null }
        <div className="center">
            <button className="btn" onClick={ (e) => { validate(e) } }>Kitas</button>
        </div>
    </form>
		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    remindCode
  }
)(RemindCodePage));