import {
  GET_PERSONNEL,
  SET_PERSONNEL_DATE,
  SET_FILTER_PERSONNEL
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_PERSONNEL:
      return {
        ...state,
        list: action.payload,
      }
    
    case SET_PERSONNEL_DATE:
      return {
        ...state,
        date: action.payload,
      }
    case SET_FILTER_PERSONNEL:
      return {
        ...state,
        filter: action.payload
      }
    default:
      return state;
  }
}