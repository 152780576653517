import {
  GET_LOCATIONS,
  RESET_LOCATIONS
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_LOCATIONS:
      let list = action.payload
      if (list === null) {
        list = [];
      }
      return {
        ...state,
        list: list,
      }
    case RESET_LOCATIONS:
      return {
        ...state,
        list: [],
      }
    default:
      return state;
  }
}