import {
    GET_TERRITORIES
} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case GET_TERRITORIES:
            let list = action.payload
            if (list === null) {
                list = [];
            }
            return {
                ...state,
                list: list,
            }
        default:
            return state
    }
}