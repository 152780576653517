import React, { Component } from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'

export default class extends Component {

  constructor(props) {
    super(props)
    Modal.setAppElement('#root')
  }

  render() {

    return (
        <Modal
          isOpen={ this.props.open } 
          contentLabel="Modal" 
          className={{
            base: 'filter-modal',
            afterOpen: 'filter-modal_after-open',
            beforeClose: 'filter-modal_before-close'
          }}
          overlayClassName={{
            base: 'action-overlay',
            afterOpen: 'action-overlay_after-open',
            beforeClose: 'action-overlay_before-close'
          }} 
          onRequestClose={ () => { this.props.hide() } } 
          closeTimeoutMS={ 300 }
        >
          <div className="popup-inner">
            { this.props.children }
          </div>
        </Modal>
    )
  }
}
