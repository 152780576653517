import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setFilter } from '../../actions/personnel';
import BaseComponent from "../Helpers";
import Button from "../Button";
import _ from 'lodash';
import SearchInput, {createFilter} from 'react-search-input'
import SearchField from "../SearchField"
import "react-datepicker/dist/react-datepicker.css";
import { getLocations, resetLocations} from '../../actions/locations';
import { getTerritories } from '../../actions/territories';
import { setDate, getPersonnel } from '../../actions/personnel';

class AddEditAction extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      territoryFilter: this.props.personnel.filter.territoryFilter || '',
      territoryFocus: false
    }
    this._bind('clearFilter', 'setFilter');
  }

  setFilter() {
    this.props.setFilter({
      territoryFilter: this.state.territoryFilter
    });
    this.props.hide()
  }

  clearFilter() {
    this.setState({
      territoryFilter: ''
    })

    this.props.setFilter({
      territoryFilter: ''
    });

  }

  render() {
    const filteredTerritories = this.props.personnel.list.filter(createFilter(this.state.territoryFilter, ['territories.ident']))
    
    let territories = [];
    _.forEach(filteredTerritories, (item, k)=>{
      territories = [...territories, ...item.territories]
    })

    return (
      <div className="filter-wrap">
        <div className="filters">
          <div className="location-filters">
            <h3>Vieta</h3>
            <SearchField value={this.state.territoryFilter} placeholder="Teritorija" onChangeAction={(filter) => { this.setState({ territoryFilter: filter }) }}>
                <ul className={ this.state.territoryFocus != '' ? 'active' : '' }>
                  { _.uniqBy(territories, 'ident').map((item, i) => {
                    return <li key={ i } onMouseDown={()=>{this.setState({territoryFilter: item.ident})}} >{ item.ident }</li>
                  }) }
                </ul>
              </SearchField>

          </div>
          <div className="utility-filters">
            
          </div>
        </div>
        <div className="buttons">
          <Button type="orange" action={ this.clearFilter }>Taikyti filtrą</Button>
          <Button action={ this.setFilter }>Išsaugoti</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, personnel, user, events, locations, territories } = state
  return { screen, actions, personnel, user, events, locations, territories }
};

export default withRouter(connect(
  mapStateToProps,
  {
    getLocations,
    getTerritories,
    getPersonnel,
    resetLocations,
    setDate,
    setFilter
  }
)(AddEditAction));