import {
  GET_PERSONNEL,
  ADD_TOAST,
  SET_PERSONNEL_DATE,
  SET_FILTER_PERSONNEL
} from './types';
import moment from 'moment';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'

export function getPersonnel(date, territoryId, range) {
  return (dispatch, getState) => {
    const currentState = getState();
    let data = {}

    let territory = { uuid: territoryId || currentState.user.user.territories[0].uuid }
    if (currentState.user.user.territories.length > 1) {
      const uuids = currentState.user.user.territories.map((item, key) => {
        return item.uuid
      })
      territory = {
        uuids: uuids
      }
    }

    if (range) {
      data = {
        pagination: {
          page: 0,
          pageSize: 500
        },
        territory: territory,
        availability: {
          "since": range.since,
          "until": range.until
        }
      }
    } else {
      data = {
        pagination: {
          page: 0,
          pageSize: 500
        },
        territory: territory,
        availability: {
          "since": date ? moment(date + '-1').startOf('month').format("Y-M-D") : moment().startOf('date').format("Y-M-D"),
          "until": date ? moment(date + '-1').endOf('month').format("Y-M-D") : moment().endOf('date').format("Y-M-D")
        }
      }
    }
    appFetch("POST", API_URL, "/user/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if (result.status && result.status.success) {
        if (result.data != null) {
          if (result.data.length >= 500) {
            let data = {}

            if (range) {
              data = {
                pagination: {
                  page: 1,
                  pageSize: 500
                },
                territory: territory,
                availability: {
                  "since": range.since,
                  "until": range.until
                }
              }
            } else {
              data = {
                pagination: {
                  page: 1,
                  pageSize: 500
                },
                territory: territory,
                availability: {
                  "since": date ? moment(date + '-1').startOf('month').format("Y-M-D") : moment().startOf('date').format("Y-M-D"),
                  "until": date ? moment(date + '-1').endOf('month').format("Y-M-D") : moment().endOf('date').format("Y-M-D")
                }
              }
            }

            appFetch("POST", API_URL, "/user/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result1) => {
              let newResult = [...result.data, ...result1.data]
              dispatch({ type: GET_PERSONNEL, payload: newResult });
            })
          } else {
            dispatch({ type: GET_PERSONNEL, payload: result.data });
          }
        }
      } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })

  }
}

export function setDate(date) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PERSONNEL_DATE, payload: date });
    dispatch(getPersonnel(moment(date).format("Y-M")))
  }
}

export function setFilter(filter) {
  return (dispatch, getState) => {
    dispatch({ type: SET_FILTER_PERSONNEL, payload: filter });
  }
}
