import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { NavLink } from 'react-router-dom';
import Button from "../Button";
import _ from 'lodash';
import moment from 'moment';

class PersonnelItem extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { active: false };
  }


  render() {
    const person = this.props.person;
    let editable = false;
    let status = 'unavailable';
    let availableToday = {timestart: '', timeend: ''}
    _.each(person.availability, (av, index)=>{
      if (av.since && moment(av.since.date).format('y-m-d') === moment(this.props.date).format('y-m-d')) {
        status = 'available';
        availableToday = { timestart: moment(av.since.date).format('HH:mm'), timeend: moment(av.until.date).format('HH:mm')}
      }
    })

    return (
      <tr className={ "action-row "+status }>
        <td className="action-name"><span>{ person.firstname+" "+person.lastname }</span></td>
        <td>{ availableToday.timestart+' - '+availableToday.timeend }</td>
        <td>
          <div className="buttons">
            <Button action={ this.props.openPopup }>Kalendorius</Button>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports } = state
  return { screen, reports }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(PersonnelItem));