import { 
  ADD_ACTION,
  EDIT_ACTION,
  COPY_ACTION,
  UPLOAD_ACTIONS,
  UPLOAD_ACTIONS_REQUEST,
  ADD_TOAST,
  GET_EVENTS,
  SET_MONTH,
  COPY_START,
  COPY_UPDATE,
  COPY_END,
  SET_DATE_RANGE,
  SET_FILTER,
  QUEUE_EXPORT_ADD_REQUEST,
  QUEUE_EXPORT_ADD,
  QUEUE_FINISHED,
  QUEUE_DATA
} from './types';
import { API_URL, appFetch, fileFetch } from '../helpers/fetch';
import { errors } from '../helpers/errors';
import moment from 'moment';
import { getPersonnel } from './personnel';

export function addAction(data, range) {
  return (dispatch, getState) => { 
    //dispatch({ type: ADD_ACTION, action: createAction(action) });
    const currentState = getState()
    appFetch("POST", API_URL, "/action/create", {action: data}, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch(uploadActions(0,range))
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Dodano nową akcję" } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function editAction(data, range) {
  return (dispatch, getState) => { 

    const currentState = getState()
    appFetch("POST", API_URL, "/action/update", {action: data}, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch(uploadActions(0,range))
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zapisano akcję" } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function copyAction(data, vol, range) {
  return (dispatch, getState) => { 
    const currentState = getState()
    dispatch({ type: COPY_START, payload: parseInt(vol)});
    dispatch(copyRequest(data, range));
  }
}

function copyRequest(data, range) {
  return (dispatch, getState) => { 
  const currentState = getState()
    appFetch("POST", API_URL, "/action/create", {action: data}, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Skopiowano akcję "+(currentState.actions.copyVolume + 1 - currentState.actions.copyCurrent)+'/'+currentState.actions.copyVolume } });

        if (currentState.actions.copyCurrent <= 1 ) {
          dispatch(uploadActions(0,range))
          dispatch({ type: COPY_END, payload: 0})
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Kopiowanie ukończone!" } });
        } else {
          dispatch({ type: COPY_UPDATE, payload: currentState.actions.copyCurrent - 1})
          dispatch(copyRequest(data));
        }
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function statusChange(data, range) {
  return (dispatch, getState) => { 

    const currentState = getState()
    appFetch("POST", API_URL, "/action/set-status", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch(uploadActions(0,range, undefined, false))
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Aktyvacijos statuso pasikeitimas" } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function uploadActions(m, range, page, refresh = true) {
  return (dispatch, getState) => { 
    const currentState = getState()
    let data = {};

    let cities = currentState.actions.filter?.cityFilter ? {"cities": [currentState.actions.filter.cityFilter]} : {};
    let streets = currentState.actions.filter?.streetFilter ? {"streets": [currentState.actions.filter.streetFilter]} : {};
    let territories = currentState.actions.filter?.territoryFilter ? {"territories": { "uuids": [currentState.actions.filter.territoryFilterUUID]}} : {};
    let events = currentState.actions.filter?.eventFilter ? {"events": { "uuids": [currentState.actions.filter.eventFilterUUID]}} : {};
    let statuses = currentState.actions.filter?.statusFilter ? {"statuses": { "idents": [currentState.actions.filter.statusFilter]}} : {};
    let users = currentState.actions.filter?.personnelFilter ? {"users": { "uuids": [currentState.actions.filter.personnelFilterUUID]}} : {};

    let filters = {...cities, ...streets, ...territories, ...events, ...statuses, ...users};

    if (range) {
      data = {
        "actions": {
          "since": range.since,
          "until": range.until,
          ...filters
        },
        "pagination": {
          "limit": 100,
          "offset": page*100 || currentState.actions.page*100 || 0
        }
      }
    } else {
      let month = moment().format('Y-M');
      if ( currentState.actions.month ) {
        month = currentState.actions.month
      }
      if ( m ) {
        month = m;
      }

      data = {
        "actions": {
            "since": moment(month+'-1').startOf('month').format('YYYY-MM-DD'),
            "until": moment(month+'-1').endOf('month').format('YYYY-MM-DD'),
            ...filters
        },
        "pagination": {
          "limit": 100,
          "offset": page*100 || currentState.actions.page*100 || 0
        }
      }
    }

    if(refresh) {
      dispatch({ type: UPLOAD_ACTIONS_REQUEST, payload: page || 0 });
    }

    appFetch("POST", API_URL, "/action/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: UPLOAD_ACTIONS, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function setMonth(date) {
  return (dispatch, getState) => { 
    dispatch({ type: SET_MONTH, payload: date });
    dispatch(uploadActions(date))
    dispatch(getPersonnel(date))
  }
}

export function setDateRange(since, until) {
  return (dispatch, getState) => { 
    dispatch({ type: SET_DATE_RANGE, payload: {since: since, until: until} });
    dispatch(uploadActions('', {since: since, until: until}))
  }
}

export function setFilter(filter) {
  return (dispatch, getState) => { 
    dispatch({ type: SET_FILTER, payload: filter });
    dispatch(uploadActions('', {since: getState().actions.since, until: getState().actions.until}))
  }
}

let id = 5;

function createAction(action) {
  return {
    ...action,
    id: id++
  }
}

function createCopy(action, defaultAction, copySelection) {
  console.log(defaultAction,copySelection)
  return {
    ...action,
    name: copySelection[0] ? action.name : defaultAction.name,
    event: copySelection[1] ? action.event : defaultAction.event,
    locations: copySelection[2] ? action.locations : defaultAction.locations,
    date: copySelection[3] ? action.date : defaultAction.date,
    timestart: copySelection[4] ? action.timestart : defaultAction.timestart,
    timeend: copySelection[4] ? action.timeend : defaultAction.timeend,
    personnel: copySelection[5] ? action.personnel : defaultAction.personnel,
    id: id++
  }
}

export function generateFilteredReport(actions, since, until) {
  return (dispatch, getState) => { 
    const currentState = getState()

    let cities = currentState.actions.filter.cityFilter ? {"cities": [currentState.actions.filter.cityFilter]} : {};
    let streets = currentState.actions.filter.streetFilter ? {"streets": [currentState.actions.filter.streetFilter]} : {};
    let territories = currentState.actions.filter.territoryFilter ? {"territories": { "uuids": [currentState.actions.filter.territoryFilterUUID]}} : {};
    let events = currentState.actions.filter.eventFilter ? {"events": { "uuids": [currentState.actions.filter.eventFilterUUID]}} : {};
    let statuses = currentState.actions.filter.statusFilter ? {"statuses": { "idents": [currentState.actions.filter.statusFilter]}} : {};
    let users = currentState.actions.filter.personnelFilter ? {"users": { "uuids": [currentState.actions.filter.personnelFilterUUID]}} : {};

    let filters = {...cities, ...streets, ...territories, ...events, ...statuses, ...users};

    const data = {
      "actions": {
        ...filters
      },
      "pagination": {
        "limit": 2048,
        "offset": 0
      }
    }

    fileFetch("POST", API_URL, `/action/report/bat/${since}/${until}`, data, currentState.user.token ? currentState.user.token.uuid : false, (blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "raport-"+since+"-"+until+".xlsx";
        document.body.appendChild(a); // won't work in firefox without appeding the elemnt to the DOM
        a.click();    
        a.remove();
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function queueExport(fileType, since, until, finishedOnly) {
  return (dispatch, getState) => { 
    const currentState = getState()

    let cities = currentState.actions.filter.cityFilter ? {"cities": [currentState.actions.filter.cityFilter]} : {};
    let streets = currentState.actions.filter.streetFilter ? {"streets": [currentState.actions.filter.streetFilter]} : {};
    let territories = currentState.actions.filter.territoryFilter ? {"territories": { "uuids": [currentState.actions.filter.territoryFilterUUID]}} : {};
    let events = currentState.actions.filter.eventFilter ? {"events": { "uuids": [currentState.actions.filter.eventFilterUUID]}} : {};
    let statuses = currentState.actions.filter.statusFilter ? {"statuses": { "idents": [currentState.actions.filter.statusFilter]}} : {};
    let users = currentState.actions.filter.personnelFilter ? {"users": { "uuids": [currentState.actions.filter.personnelFilterUUID]}} : {};

    let filters = {...cities, ...streets, ...territories, ...events, ...statuses, ...users};

    if (finishedOnly) {
      filters = {
        statuses: {
          idents: ['finished']
        }
      }
    }

    const data = {
      "actions": {
        ...filters
      },
      "pagination": {
        "limit": 2048,
        "offset": 0
      }
    }

    dispatch({ type: QUEUE_EXPORT_ADD_REQUEST, payload: {} });

    appFetch("POST", API_URL, `/action/report/request/${fileType}/${since}/${until}`, data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: QUEUE_EXPORT_ADD, payload: result.data });

          dispatch(listQueue())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function listQueue() {
  return (dispatch, getState) => { 

    const currentState = getState()
    appFetch("POST", API_URL, "/action/report/status", {}, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          if (result.data.queued.length === 0) {
            dispatch({ type: QUEUE_FINISHED, payload: result.data });
          } else {
            dispatch({ type: QUEUE_DATA, payload: result.data });
            setTimeout(()=>{
              dispatch(listQueue())
            }, 10000)
          }
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function downloadReport(uuid, params) {
  return (dispatch, getState) => { 

    const data = {
      "report": {
        "uuid": uuid
      }
    }
    const currentState = getState()
    fileFetch("POST", API_URL, "/action/report/download", data, currentState.user.token ? currentState.user.token.uuid : false, (blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = "raport-"+params.since+"-"+params.until+"."+params.format;
      document.body.appendChild(a); // won't work in firefox without appeding the elemnt to the DOM
      a.click();    
      a.remove();
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}
