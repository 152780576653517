import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import Button from "../Button";
import EventItem from "./EventItem";
import ActionModal from "../modals/ActionModal";
import FilterModal from "../modals/FilterModal";
import AddEditEvent from "./AddEditEvent";
import Filter from "../modals/Filter";
import moment from 'moment';
import { getEvents } from '../../actions/events';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import _ from 'lodash';
import {createFilter} from 'react-search-input';

class EventsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      addEditPopup: false, 
      eventID: -1, 
      copy: false, 
      dropdown: false, 
      filterPopup: false,
      since: moment(), 
      until: moment().add(7, 'days')
    };
    this._bind('openActionPopup', 'hideActionPopup', 'hideFilterPopup', 'showFilterPopup');
  }s

  componentWillMount() {
    this.props.getEvents('',{since: moment().format('YYYY-MM-DD'), until: moment().add(7, 'days').format('YYYY-MM-DD')})
  }

  openActionPopup(id, copy) {
    this.setState({addEditPopup: true, eventID: id, copy: copy})
  }

  hideActionPopup() {
    this.setState({addEditPopup: false })
  }

  hideFilterPopup() {
    this.setState({filterPopup: false })
  }

  showFilterPopup() {
    this.setState({filterPopup: true })
  }

  render() {

    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar"  onClick={onClick}/>
    );

    return (
      <section className="actions events">
        <div className="page-header actions-header">
          <h1>Renginiai</h1>
          <Button type="add-action" action={ ()=>{ this.openActionPopup(-1) } }>Dodaj event<span>+</span></Button>
        </div>
        <table className={ this.state.addEditPopup ? "actions-table popup-active" : "actions-table" }>
          <thead>
            <tr>
              <td>Nr</td>
              <td>Vardas</td>
              <td>Id</td>
              <td>Pradžios data</td>
              <td>Pabaigos data</td>
              <td>Teritorija</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            { this.props.events.list.map((item, key) => {
                return (
                  <EventItem key={'action-'+key} nr={key+1} event={ item } openPopup={ this.openActionPopup } range={{since: this.state.since.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}} />
                )
              })
            }
          </tbody>
        </table>
        <ActionModal 
          open={ this.state.addEditPopup } 
          hide={ this.hideActionPopup } >
          <AddEditEvent ident={ this.state.eventID } hide={ this.hideActionPopup } range={{since: this.state.since.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}} />
        </ActionModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, events, user, personnel, } = state
  return { screen, events, user, personnel }
};

export default withRouter(connect(
  mapStateToProps,
  {
    getEvents
  }
)(EventsPage));