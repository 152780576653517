import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import Button from "../Button";
import ActionItem from "./ActionItem";
import ActionModal from "../modals/ActionModal";
import FilterModal from "../modals/FilterModal";
import AddEditAction from "./AddEditAction";
import Filter from "../modals/Filter";
import CopyAction from "./CopyAction";
import moment from 'moment';
import { uploadActions, generateFilteredReport, setDateRange } from '../../actions/actions';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import _ from 'lodash';
import {createFilter} from 'react-search-input';
import Dropdown from "../Dropdown";
import ReactPaginate from 'react-paginate';
import Export from '../modals/Export';
import ExportModal from '../modals/ExportModal';

class ActionsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      addEditPopup: false, 
      actionID: -1, 
      copy: false, 
      dropdown: false, 
      filterPopup: false,
      exportPopup: false,
      since: moment(), 
      until: moment().add(7, 'days'),
      page: 0
    };
    this._bind('openActionPopup', 'hideActionPopup', 'hideFilterPopup', 'showFilterPopup', 'showExportPopup', 'hideExportPopup');
  }s

  componentWillMount() {
    this.props.setDateRange(moment().format('YYYY-MM-DD'), moment().add(7, 'days').format('YYYY-MM-DD'))
  }

  openActionPopup(id, copy) {
    this.setState({addEditPopup: true, actionID: id, copy: copy})
  }

  hideActionPopup() {
    this.setState({addEditPopup: false })
  }

  hideFilterPopup() {
    this.setState({filterPopup: false })
  }

  showFilterPopup() {
    this.setState({filterPopup: true })
  }

  hideExportPopup() {
    this.setState({exportPopup: false })
  }

  showExportPopup() {
    this.setState({exportPopup: true })
  }

  render() {
    const sinceDate = this.state.since.toDate();
    const untilDate = this.state.until.toDate();

    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar"  onClick={onClick}/>
    );

    ///
    
    /*let filteredCities = this.props.actions.list || [];
    let filteredStreets = this.props.actions.list || [];
    let filteredIds = this.props.actions.list || [];
    let filteredPersonnel = this.props.actions.list || [];
    let filteredEvents = this.props.actions.list || [];
    let filteredTerritories = this.props.actions.list || [];
    let filteredStatuses = this.props.actions.list || [];

    if ( this.props.actions.filter.cityFilter) {
      filteredCities = this.props.actions.list.filter(createFilter(this.props.actions.filter.cityFilter, ['actionPoints.address.cityName']))
    }
    if ( this.props.actions.filter.streetFilter) {
      filteredStreets = this.props.actions.list.filter(createFilter(this.props.actions.filter.streetFilter, ['actionPoints.address.streetAddress']))
    }
    if ( this.props.actions.filter.idFilter) {
      filteredIds = this.props.actions.list.filter(createFilter(this.props.actions.filter.idFilter, ['ident']))
    }
    if ( this.props.actions.filter.personnelFilter) {
      filteredPersonnel = this.props.actions.list.filter(createFilter(this.props.actions.filter.personnelFilter, ['users.firstname', 'users.lastname']))
    }
    if ( this.props.actions.filter.eventFilter) {
      filteredEvents = this.props.actions.list.filter(createFilter(this.props.actions.filter.eventFilter, ['event.name']))
    }
    if ( this.props.actions.filter.territoryFilter) {
      filteredTerritories = this.props.actions.list.filter(createFilter(this.props.actions.filter.territoryFilter, ['territory.ident']))
    }

    if ( this.props.actions.filter.statusFilter) {
      filteredStatuses = this.props.actions.list.filter(createFilter(this.props.actions.filter.statusFilter, ['status.global.ident']))
    }

    const filteredActions = _.intersection(filteredCities, filteredStreets, filteredIds, filteredPersonnel, filteredEvents, filteredTerritories, filteredStatuses)*/

    const filteredActions = this.props.actions.list;

    const filteredActionsIDs = filteredActions.map((item, key)=>{
      return item.uuid;
    })

    return (
      <section className="actions">
        <div className="page-header actions-header">
          <h1>Aktyvacijos</h1>
          <a className="btn export-btn" onClick={ this.showExportPopup }>Eksportuoti</a>
          <a className="btn export-btn" onClick={ this.showFilterPopup } target="_blank">Filtruoti</a>
          <Dropdown classNames="calendar-dropdown" selected={ this.state.since.format('YYYY.MM.DD')+' - '+this.state.until.format('YYYY.MM.DD') }>
            <>
              <div className="since-date">
                <span>od </span>
                <label>{ this.state.since.format('YYYY.MM.DD') }</label>
                <DatePicker
                  selected={sinceDate}
                  locale="lt"
                  popperPlacement="bottom-right"
                  onChange={ (sinceDate) => {
                    let dt = moment(sinceDate);
                    this.setState({since: dt})
                    this.props.uploadActions('',{since: dt.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}, 0)
                  } }
                  customInput={<CalendarInput/>}
                />
              </div>
              <div className="since-date">
                <span>do </span>
                <label>{ this.state.until.format('YYYY.MM.DD') }</label>
                <DatePicker
                  selected={untilDate}
                  locale="lt"
                  popperPlacement="bottom-right"
                  onChange={ (untilDate) => {
                    let dt = moment(untilDate);
                    this.setState({until: dt})
                    this.props.uploadActions('', {since: this.state.since.format('YYYY-MM-DD'), until: dt.format('YYYY-MM-DD')}, 0)
                  } }
                  customInput={<CalendarInput/>}
                />
              </div>
            </>
          </Dropdown>
          <Button type="add-action" action={ ()=>{ this.openActionPopup(-1) } }>Pridėti aktyvaciją<span>+</span></Button>
        </div>
        <table className={ this.state.addEditPopup ? "actions-table popup-active" : "actions-table" }>
          <thead>
            <tr>
              <td>Statusas</td>
              <td>Nr</td>
              <td>Aktyvacijos pavadinimas</td>
              <td>Aktyvacijos ID</td>
              { this.props.user.user && (this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ? <td>Teritorija</td> : null) }
              <td>Veikla</td>
              <td>Vieta</td>
              <td>Data</td>
              <td>Laikas</td>
              <td>Asmuo</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            { filteredActions.map((item, key) => {
                return (
                  <ActionItem key={'action-'+key} nr={key+1} action={ item } openPopup={ this.openActionPopup } range={{since: this.state.since.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}} />
                )
              })
            }
          </tbody>
        </table>
        <ActionModal 
          open={ this.state.addEditPopup } 
          hide={ this.hideActionPopup } >
          { this.state.copy ? <CopyAction ident={ this.state.actionID } hide={ this.hideActionPopup } range={{since: this.state.since.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}} /> :
            <AddEditAction ident={ this.state.actionID } hide={ this.hideActionPopup } range={{since: this.state.since.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}} />
          }
        </ActionModal>
        <FilterModal 
          open={ this.state.filterPopup } 
          hide={ this.hideFilterPopup } >
            <Filter hide={ this.hideFilterPopup } />
        </FilterModal>
        <ExportModal 
          open={ this.state.exportPopup } 
          hide={ this.hideExportPopup } >
            <Export hide={ this.hideExportPopup } finished={ false } />
        </ExportModal>
        { this.props.actions.count > 100 ?
          <ReactPaginate
            previousLabel={'Ankstesnis'}
            nextLabel={'Toliau'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.props.actions.maxPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data) => { this.props.uploadActions('',{since: this.state.since.format('YYYY-MM-DD'), until: this.state.until.format('YYYY-MM-DD')}, data.selected) }}
            containerClassName={'pagination'}
            activeClassName={'active'}
          /> : null }
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, user, personnel } = state
  return { screen, actions, user, personnel }
};

export default withRouter(connect(
  mapStateToProps,
  {
    uploadActions,
    generateFilteredReport,
    setDateRange
  }
)(ActionsPage));