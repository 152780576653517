import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { addAction, editAction, copyAction } from '../../actions/actions';
import BaseComponent from "../Helpers";
import Button from "../Button";
import _, { map } from 'lodash';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg'
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import SearchInput, { createFilter } from 'react-search-input'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import lt from 'date-fns/locale/lt';
import { getLocations, resetLocations } from '../../actions/locations';
import { getTerritories } from '../../actions/territories';
import { setDate, getPersonnel } from '../../actions/personnel';
registerLocale('lt', lt)

class AddEditAction extends BaseComponent {

  constructor(props) {
    super(props)
    let action = _.find(this.props.actions.list, ['ident', this.props.ident]);
    if (action === undefined) {
      action = {
        new: true,
        ident: "",
        event: { name: "" },
        actionPoints: [],
        users: [],
        territory: {
          uuid: this.props.user.user.territories[0].uuid,
          ident: ''
        },
        area: {
          uuid: this.props.user.user.areas[0].uuid
        },
        excerpt: "",
        description: ""
      };
    }

    this.state = {
      action: action,
      activeInput: "actionName", // actionName, eventName, location, date, time, personel
      selectedEvent: {},
      selectedLocations: [],
      selectedPersonnel: [],
      day: action.since ? moment(action.since.date).format('D') : '',
      month: action.since ? moment(action.since.date).format('M') : '',
      year: action.since ? moment(action.since.date).format('Y') : '',
      timestart: action.since ? moment(action.since.date).format('HH:mm') : '',
      timeend: action.until ? moment(action.until.date).format('HH:mm') : '',
      locationFilter: "",
      personnelFilter: "",
      eventFilter: "",
      territory: ""
    };
    this._bind('handleDateChange', 'handleTimeChange')
  }

  componentDidMount() {
    if (this.state.action.event.uuid) {
      this.props.getLocations(this.state.action.event.uuid);
    } else {
      this.props.resetLocations();
    }
    this.props.getTerritories(this.state.action.event.uuid);
  }

  handleInputChange(e) {
    const target = e.target
    let value = target.type === 'checkbox' ? target.checked : target.value

    this.setState({
      action: { ...this.state.action, name: value }
    })
  }

  selectEvent(event, index) {
    this.setState({
      action: { ...this.state.action, event: { uuid: event.uuid, name: event.name } },
      selectedEvent: event.uuid
    })
    this.props.getLocations(event.uuid, this.state.selectedTerritory)
  }

  selectTerritory(territory, index) {
    this.setState({
      action: { ...this.state.action, territory: { uuid: territory.uuid, ident: territory.ident } },
      selectedTerritory: territory.uuid
    })
    this.props.getLocations(this.state.selectedEvent, territory.uuid);
    this.props.getPersonnel(this.props.actions.month, territory.uuid);
  }

  selectLocation(location, index) {
    let actionPoints = this.state.action.actionPoints;
    if (this.state.action.actionPoints.length > 0) {
      let exists = false;
      _.each(actionPoints, (item) => {
        if (location.uuid === item.point.uuid) {
          item.trash = !item.trash;
          exists = true;
        } else {
          item.trash = true;
        }
      })
      if (!exists) {
        actionPoints.push({ trash: false, point: { uuid: location.uuid }, address: location.address, name: location.name, ident: location.ident })
      }
    } else {
      actionPoints = [{ trash: false, point: { uuid: location.uuid }, address: location.address, name: location.name, ident: location.ident }];
    }

    this.setState({
      action: { ...this.state.action, actionPoints: actionPoints }
    })
  }

  /*selectLocation(location, index) {
    if (this.state.selectedLocations.indexOf(index) >= 0) {
      this.setState({
        action: { ...this.state.action, actionPoints: this.state.action.actionPoints.filter((item)=>{
          console.log(item.ident, location.ident)
          return item.ident !== location.ident
        })},
        selectedLocations: this.state.selectedLocations.filter((item)=>{
          console.log(item,index)
          return item !== index
        })
      })
    } else {
      this.setState({
        action: { ...this.state.action, actionPoints: [...this.state.action.actionPoints, { trash: false, point: { uuid: location.uuid }, address: location.address, name: location.name, ident: location.ident}] },
        selectedLocations: [...this.state.selectedLocations, index]
      })
    }
  }*/

  selectPersonnel(personnel, index) {
    let users = this.state.action.users
    if (this.state.action.users && this.state.action.users.length > 0) {
      let exists = false;
      _.each(users, (item) => {
        if (personnel.uuid === item.uuid) {
          item.trash = !item.trash;
          exists = true;
        } else {
          item.trash = true;
        }
      })
      if (!exists) {
        users.push({ trash: false, uuid: personnel.uuid, firstname: personnel.firstname, lastname: personnel.lastname, ident: personnel.ident })
      }
    } else {
      users = [{ trash: false, uuid: personnel.uuid, firstname: personnel.firstname, lastname: personnel.lastname, ident: personnel.ident }];
    }

    this.setState({
      action: { ...this.state.action, users: users }
    })
  }

  /*selectPersonnel(personnel, index) {
    if (this.state.selectedPersonnel.indexOf(index) >= 0) {
      this.setState({
        action: { ...this.state.action, users: this.state.action.users.filter((item)=>{
          return item.ident !== personnel.ident
        })},
        selectedPersonnel: this.state.selectedPersonnel.filter((item)=>{
          return item !== index
        })
      })
    } else {
      this.setState({
        action: { ...this.state.action, users: [...this.state.action.users, { trash: false, uuid: personnel.uuid, firstname: personnel.firstname, lastname: personnel.lastname, ident: personnel.ident}] },
        selectedPersonnel: [...this.state.selectedPersonnel, index]
      })
    }
  }*/

  handleDateChange(e) {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let day = this.state.day;
    let month = this.state.month;
    let year = this.state.year;
    if (name === 'day') day = value;
    if (name === 'month') month = value;
    if (name === 'year') year = value;
    if (moment(month + '.' + day + '.' + year).isValid()) {
      this.setState({
        day: day,
        month: month,
        year: year,
        action: { ...this.state.action, since: { date: moment(month + '.' + day + '.' + year + ' ' + this.state.timestart).format("YYYY-MM-DD HH:mm:ss") }, until: { date: moment(month + '.' + day + '.' + year + ' ' + this.state.timeend).format("YYYY-MM-DD HH:mm:ss") } }
      })
      this.props.setDate(month + '.' + day + '.' + year)
    } else {
      this.setState({
        day: day,
        month: month,
        year: year
      })
    }
  }

  handleTimeChange(e) {
    const target = e.target;
    let value = target.value;
    let formatedValue = value;
    let name = target.name;
    if (value.indexOf(':') === -1) {
      formatedValue += ':00';
    }
    if (name === 'timestart') {
      this.setState({
        action: { ...this.state.action, since: { date: moment(this.state.year + '-' + this.state.month + '-' + this.state.day + ' ' + formatedValue).format("YYYY-MM-DD HH:mm:ss") } },
        timestart: value
      })
    } else {
      this.setState({
        action: { ...this.state.action, until: { date: moment(this.state.year + '-' + this.state.month + '-' + this.state.day + ' ' + formatedValue).format("YYYY-MM-DD HH:mm:ss") } },
        timeend: value
      })
    }
  }

  render() {
    const action = this.state.action;
    let inputBox = null;

    const icons = {
      'active': <ActiveIcon />,
      'new': <NewIcon />,
      'canceled': <CanceledIcon />,
      'ended': <EndedIcon />,
      'accepted': <ApprovedIcon />
    }

    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar" onClick={onClick} />
    );

    switch (this.state.activeInput) {
      case "actionName":
        inputBox = (
          <div className="input-box input-box-name">
            <h3>Pridėti aktyvacijos pavadinimą</h3>
            <input type="text" name="name" value={action.name} onChange={(e) => { this.handleInputChange(e) }} placeholder="Aktyvacijos pavadinimas" />
            <h3>Paskutinės ieškotos aktyvacijos</h3>
            <div className="buttons">
              <Button action={() => { this.setState({ activeInput: this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ? 'territory' : 'eventName' }) }}>Toliau</Button>
            </div>
          </div>
        )
        break;
      case "territory":
        const territories = []
        const filteredTerritories = this.props.territories.list.filter(createFilter(this.state.eventFilter, ['name']))

        inputBox = (
          <div className="input-box input-box-territory">
            <h3>Veiklos paieška</h3>
            <SearchInput className="search-input" placeholder="Teritorijos paieška" onChange={(filter) => {
              this.setState({ eventFilter: filter })
            }} />
            <ul>
              {filteredTerritories.map((item, i) => {
                return <li key={i} className={this.state.action.territory.uuid === item.uuid ? 'active' : ''} onClick={() => { this.selectTerritory(item, i); this.setState({ territory: item.uuid }) }}>{item.ident}</li>
              })}
            </ul>
            <div className="buttons">
              <Button action={() => { this.setState({ activeInput: 'eventName' }) }}>Toliau</Button>
            </div>
          </div>
        )
        break;
      case "eventName":
        const events = []
        const filteredEvents = this.props.events.list.filter(createFilter(this.state.eventFilter, ['name']))

        inputBox = (
          <div className="input-box input-box-event">
            <h3>Veiklos paieška</h3>
            <SearchInput className="search-input" placeholder="Ieškoti renginio..." onChange={(filter) => {
              this.setState({ eventFilter: filter })
            }} />
            <ul>
              {filteredEvents.map((item, i) => {
                return <li key={i} className={this.state.action.event.uuid === item.uuid ? 'active' : ''} onClick={() => { this.selectEvent(item, i) }}>{item.name}</li>
              })}
            </ul>
            <div className="buttons">
              <Button action={() => { this.setState({ activeInput: 'location' }) }}>Toliau</Button>
            </div>
          </div>
        )
        break;
      case "location":
        const locations = [

        ]

        const filteredLocations = this.props.locations.list.filter(createFilter(this.state.locationFilter, ['name', 'address.streetAddress']))

        inputBox = (
          <div className="input-box input-box-location">
            <h3>Pridėti vietą:</h3>
            <SearchInput className="search-input" placeholder="Ieškoti pagal pavadinimą ir vietą" onChange={(filter) => {
              this.setState({ locationFilter: filter })
            }} />
            <ul>
              {filteredLocations.map((item, i) => {
                return <li key={i} className={this.state.action.actionPoints != null && this.state.action.actionPoints.length > 0 && this.state.action.actionPoints[0].uuid === item.uuid ? 'active' : ''} onClick={() => { this.selectLocation(item, i) }}>{item.name + ', ' + item.address.streetAddress + ', ' + item.address.cityName}</li>
              })}
            </ul>
            <div className="buttons">
              <Button action={() => { this.setState({ activeInput: 'date' }) }}>Toliau</Button>
            </div>
          </div>
        )
        break;
      case "date":
        const date = this.state.day ? moment(this.state.day + "-" + this.state.month + "-" + this.state.year, "DD-MM-YYYY").toDate() : moment().toDate()
        inputBox = (
          <div className="input-box input-box-date">
            <h3>Pasirinkti dieną</h3>
            <div className="date-inputs">
              <input onChange={this.handleDateChange} value={this.state.day} name="day" placeholder="diena" />
              <input onChange={this.handleDateChange} value={this.state.month} name="month" placeholder="mėnuo" />
              <input onChange={this.handleDateChange} value={this.state.year} name="year" placeholder="metai" />
              <DatePicker
                selected={date}
                locale="lt"
                onChange={(date) => {
                  let dt = moment(date);
                  this.setState({
                    day: dt.format("DD"),
                    month: dt.format("MM"),
                    year: dt.format("YYYY"),
                    action: { ...this.state.action, since: { date: moment(dt.format("YYYY-MM-DD") + ' ' + this.state.timestart).format("YYYY-MM-DD HH:mm:ss") }, until: { date: moment(dt.format("YYYY-MM-DD") + ' ' + this.state.timeend).format("YYYY-MM-DD HH:mm:ss") } }
                  })
                  this.props.setDate(dt.format("YYYY-MM-DD"))
                }}
                customInput={<CalendarInput />}
              />
            </div>
            <div className="buttons">
              <Button action={() => { this.setState({ activeInput: 'time' }) }}>Toliau</Button>
            </div>
          </div>
        )
        break;
      case "time":
        inputBox = (
          <div className="input-box input-box-time">
            <h3>Pasirinkite norimą laiką</h3>
            <div className="time-inputs">
              <span>nuo</span>
              <input onChange={this.handleTimeChange} value={this.state.timestart} name="timestart" placeholder="8:00" />
              <span>iki</span>
              <input onChange={this.handleTimeChange} value={this.state.timeend} name="timeend" placeholder="21:00" />
            </div>
            <div className="buttons">
              <Button action={() => { this.setState({ activeInput: 'personnel' }) }}>Toliau</Button>
            </div>
          </div>
        )
        break;
      case "personnel":
        const filteredPersonnel = this.props.personnel.list ? this.props.personnel.list.filter(createFilter(this.state.personnelFilter, ['firstname', 'lastname'])) : [];

        inputBox = (
          <div className="input-box input-box-personnel">
            <h3>Veiklos paieška</h3>
            <SearchInput className="search-input" placeholder="Ieškoti pagal vardą ir pavardę" onChange={(filter) => {
              this.setState({ personnelFilter: filter })
            }} />
            <ul>
              {filteredPersonnel.map((item, key) => {
                let cl = '';
                let availableThisDay = '';
                if (this.state.action.since && this.state.action.until && item.availability != null) {
                  _.each(item.availability, (av, index) => {
                    if (moment(av.since.date).isSame(this.state.action.since.date, 'day')) {
                      availableThisDay = moment(av.since.date).format('HH:mm') + ' - ' + moment(av.until.date).format('HH:mm');

                      if (moment(av.since.date).isSameOrBefore(this.state.action.since.date) && (moment(av.until.date).isSameOrAfter(this.state.action.until.date))) {
                        //console.log(item.firstname, av.since.date, this.state.action.since.date, av.until.date, this.state.action.until.date)
                        cl = 'available';
                      }
                    }
                  })
                }

                if (item.actions != null && item.actions.length > 0) {
                  cl = cl + ' taken';
                }

                let check = false;
                if (this.state.action.users != null && this.state.action.users.length > 0) {
                  if (this.state.action.users[0].uuid === item.uuid && !this.state.action.users[0].trash) {
                    check = true;
                  }
                  if (this.state.action.users[1] && this.state.action.users[1].uuid === item.uuid && !this.state.action.users[1].trash) {
                    check = true;
                  }
                }
                if (item.role.uuid === 'HST') {
                  return (
                    <li key={"personnel-" + key} className={check ? cl + ' hostess-name active' : 'hostess-name ' + cl} onClick={() => { this.selectPersonnel(item, key) }}><span>{item.firstname + ' ' + item.lastname}</span><span>{availableThisDay}</span></li>
                  )
                }
              })
              }
            </ul>
            <div className="buttons">
              <Button
                action={() => {
                  this.state.action.new ?
                    this.props.addAction(this.state.action, this.props.range) :
                    this.props.editAction(this.state.action, this.props.range);
                  this.props.hide();
                }
                }>Pridėti</Button>
            </div>
          </div>
        )
        break;
      default:
        break;
    }

    const territory = this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ? <td onClick={() => { this.setState({ activeInput: "territory" }) }}>{action.territory.ident === '' ? 'Teritorija' : action.territory.ident}</td> : null;

    return (
      <div className="add-edit-holder">
        <table className="add-edit-table">
          <tbody>
            <tr className={"action-row new"}>
              <td className="status-cell" tabIndex="0" onBlur={() => { this.setState({ statusSelect: false }) }}>
                <div className={"status-icon"} onClick={() => { this.setState({ statusSelect: true }) }}>{icons['new']}</div>
              </td>
              <td onClick={() => { this.setState({ activeInput: "actionName" }) }} className="action-name"><span>{action.name === '' ? 'Aktyvacijos pavadinimas' : action.name}</span></td>
              <td>{action.ident}</td>
              {territory}
              <td onClick={() => { this.setState({ activeInput: "eventName" }) }}>{action.event.name === '' ? 'Veiklos pavadinimas' : action.event.name}</td>
              <td onClick={() => { this.setState({ activeInput: "location" }) }}>
                {action.actionPoints ? action.actionPoints.length > 0 ?
                  action.actionPoints.map((item, key) => {
                    if (!item.trash) {
                      return <p key={key}>{item.name + ', ' + item.address.streetAddress + ' ' + item.address.streetNumber + ', ' + item.address.cityName}</p>
                    }
                  }) : 'Vieta'
                  : 'Vieta'}
              </td>
              <td onClick={() => { this.setState({ activeInput: "date" }) }}>{action.since ? moment(action.since.date).format("D/M/Y") : "Data"}</td>
              <td onClick={() => { this.setState({ activeInput: "time" }) }}>{action.since && action.until ? moment(action.since.date).format("H:mm") + ' - ' + moment(action.until.date).format("H:mm") : "Kalendorius"}</td>
              <td onClick={() => { this.setState({ activeInput: "personnel" }) }}>
                {action.users ? action.users.length > 0 ?
                  action.users.map((item, key) => {
                    if (!item.trash) {
                      return <p key={key}>{item.firstname + ' ' + item.lastname}</p>
                    }
                  }) : 'Darbuotojas'
                  : 'Darbuotojas'}
              </td>
              <td>
                <div className="buttons">
                  <Button
                    action={() => {
                      this.state.action.new ?
                        this.props.addAction(this.state.action, this.props.range) :
                        this.props.editAction(this.state.action, this.props.range);
                      this.props.hide();
                    }
                    }>Pridėti</Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {inputBox}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, personnel, user, events, locations, territories } = state
  return { screen, actions, personnel, user, events, locations, territories }
};

export default withRouter(connect(
  mapStateToProps,
  {
    addAction,
    editAction,
    copyAction,
    getLocations,
    getTerritories,
    getPersonnel,
    resetLocations,
    setDate
  }
)(AddEditAction));