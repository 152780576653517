import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import PersonnelItem from "./PersonnelItem";
import Modal from "../modals/Modal";
import Calendar from '../calendar/Calendar';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import lt from 'date-fns/locale/lt';
import moment from 'moment';
import { setDate } from '../../actions/personnel';
import FilterModal from "../modals/FilterModal";
import FilterPersonnel from "../modals/FilterPersonnel";
import {createFilter} from 'react-search-input';
registerLocale('lt', lt)

class ReportsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { popup: false, filterPopup: false };
    this._bind('openPopup', 'hidePopup', 'showFilterPopup', 'hideFilterPopup');
  }

  componentWillMount() {
    this.props.setDate(moment().format("Y-M-D"));
  }

  openPopup() {
    this.setState({popup: true})
  }

  hidePopup() {
    this.setState({popup: false })
  }

  hideFilterPopup() {
    this.setState({filterPopup: false })
  }

  showFilterPopup() {
    this.setState({filterPopup: true })
  }

  render() {
    
    let filteredTerritories = this.props.personnel.list || [];

    if ( this.props.personnel.filter.territoryFilter) {
      filteredTerritories = this.props.personnel.list.filter(createFilter(this.props.personnel.filter.territoryFilter, ['territories.ident']))
    }

    const filteredPersonnel = filteredTerritories;

    return (
      <section className="personnel">
        <div className="page-header actions-header">
          <h1>Komandos narys</h1>
          { this.props.user.user.role.uuid === "ASM" ?
          <a className="btn export-btn" onClick={ this.showFilterPopup } target="_blank">Filtruoti</a> : null }
          <div className="calendar-dropdown">
            <DatePicker
              selected={moment(this.props.personnel.date).toDate()}
              locale="lt"
              onChange={ (date) => {
                this.props.setDate(moment(date).format("Y-M-D"));
              } }
              customInput={
                <p className="selected">{ moment(this.props.personnel.date).format("DD MMMM YYYY") }</p>
              }
            />
          </div>
        </div>
        <table className="personnel-table">
          <thead>
            <td>Vardas ir Pavardė</td>
            <td>Laisvos valandos</td>
            <td></td>
          </thead>
          <tbody>
            { filteredPersonnel.map((item, key) => {
              if (item.role.uuid === "HST") {
                return (
                  <PersonnelItem person={ item } date={ this.props.personnel.date } openPopup={ ()=>{ this.openPopup(); this.setState({selectedPerson: item}) } } />
                )
              }
              })
            }
          </tbody>
        </table>
        <Modal 
          open={ this.state.popup } 
          hide={ this.hidePopup } >
          <Calendar person={ this.state.selectedPerson }/>
        </Modal>
        <FilterModal 
          open={ this.state.filterPopup } 
          hide={ this.hideFilterPopup } >
            <FilterPersonnel hide={ this.hideFilterPopup } />
        </FilterModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, personnel, user } = state
  return { screen, personnel, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    setDate
  }
)(ReportsPage));