import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import Button from "../Button";
import { statusChange } from '../../actions/actions';
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import moment from 'moment';
import { addToast } from '../../actions/toast';

class ActionItem extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { statusSelect: false };
  }

  changeStatus(action, newStatus) {
    if (this.checkFields(action)) {
      this.props.statusChange({ 
        status: { 
          ident: newStatus
        }, 
        action: {
          uuid: action.uuid
        }
      }, this.props.range)
    } else {
      this.props.addToast({ type: "error", message: "Užpildykite trūkstamus duomenis" });
    }
  }

  checkFields(action) {
    if (action.event != null && action.users != null && action.actionPoints != null && action.since != null && action.until != null) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const action = this.props.action;
    let editable = false;
    if (action.status.global.ident != 'cancelled' && action.status.global.ident != 'started' && action.status.global.ident != 'finished') {
      editable = true
    }
    const icons = {
      'started': <ActiveIcon />,
      'editable': <NewIcon />,
      'cancelled': <CanceledIcon />,
      'finished': <EndedIcon />,
      'accepted': <ApprovedIcon />
    }

    const territory = this.props.user.user && (this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "ADMIN" || this.props.user.user.role.uuid === "HSADM") ? <td>{ action.territory != null ? action.territory.ident : '' }</td> : null

    return (
      <tr className={ "action-row "+action.status.global.ident }>
        <td className="status-cell" tabIndex="0" onBlur={ () => { this.setState({ statusSelect: false }) } }>
          <div className={ "status-icon" } onClick={ () => { this.setState({ statusSelect: true }) } }>{icons[action.status.global.ident]}</div>
          <ul className={ this.state.statusSelect ? "status-select open" : "status-select" }>
            <li onClick={ () => {
              this.setState({ statusSelect: false })
              this.changeStatus(action, "editable")
            } }><NewIcon /></li>
            <li onClick={ () => {
              this.setState({ statusSelect: false })
              this.changeStatus(action, "accepted")
            } }><ApprovedIcon /></li>
            <li onClick={ () => {
              this.setState({ statusSelect: false })
              this.changeStatus(action, "cancelled")
            } }><CanceledIcon /></li>
          </ul>
        </td>
        <td className="action-nr"><span>{ this.props.nr }</span></td>
        <td className="action-name"><span>{ action.name }</span></td>
        <td>{ action.ident }</td>
        { territory }
        <td>{ action.event != null ? action.event.name : '' }</td>
        <td>
          { action.actionPoints.map((item, key) => {
            return <p key={ key }>{ item.name+', '+item.address.streetAddress+' '+ (item.address.streetNumber != null ? item.address.streetNumber : '') +' '+item.address.cityName }</p>
          })}
        </td>
        <td>{ action.since != null ? moment(action.since.date).format('D/M/Y') : '' }</td>
        <td>{ action.since != null && action.until != null ? moment(action.since.date).format('H:mm')+' - '+moment(action.until.date).format('H:mm') : '' }</td>
        <td>
          { action.users? action.users.map((item, key) => {
            return <p key={ "item-"+key }>{ item.firstname+' '+item.lastname }</p>
          }) : null }
        </td>
        <td>
          <div className="buttons">
            <Button action={ ()=>{this.props.openPopup(action.ident, true)} }>Kopijuoti</Button>
            { editable ? <Button action={ ()=>{this.props.openPopup(action.ident)} }>Redaguoti</Button>: null }
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, user } = state
  return { screen, actions, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    statusChange,
    addToast
  }
)(ActionItem));