import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg';
import { remind } from '../actions/user';

function RemindPage(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Įveskite el.paštą")
    } else {
      props.remind({username: email}, history)
    }
  }

  return (
    <section className="remind-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
        <h2>Slaptažodžio atstatymas</h2>
        <p>Įveskite prisijungimo vardą</p>
        <form>
          <div className="center">
            <input type="email" placeholder="prisijungimo vardas" name="email" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="center">
            <button className="btn" onClick={ (e) => { validate(e) } }>Pirmyn</button>
          </div>
        </form>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    remind
  }
)(RemindPage));