import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import moment from 'moment';

class ReportItem extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { active: false };
  }


  render() {
    const action = this.props.action;
    let editable = false;
    if (action.status.global.ident != 'cancelled' && action.status != 'active' && action.status != 'finished') {
      editable = true
    }
    const icons = {
      'active': <ActiveIcon />,
      'editable': <NewIcon />,
      'cancelled': <CanceledIcon />,
      'finished': <EndedIcon />,
      'accepted': <ApprovedIcon />
    }

    const territory = this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ? <td>{ action.territory != null ? action.territory.ident : '' }</td> : null

    return (
      <tr className={ "action-row "+action.status.global.ident }>
        <td className="status-cell" tabIndex="0" onBlur={ () => { this.setState({ statusSelect: false }) } }>
          <div className={ "status-icon" }>{icons[action.status.global.ident]}</div>
        </td>
        <td className="action-nr"><span>{ this.props.nr }</span></td>
        <td className="action-name"><span>{ action.name }</span></td>
        <td>{ action.ident }</td>
        { territory }
        <td>{ action.event != null ? action.event.name : '' }</td>
        <td>
          { action.actionPoints.map((item, key) => {
            return <p key={ key }>{ item.name+', '+item.address.streetAddress+' '+ (item.address.streetNumber != null ? item.address.streetNumber : '') +' '+item.address.cityName }</p>
          })}
        </td>
        <td>{ action.since != null ? moment(action.since.date).format('D/M/Y') : '' }</td>
        <td>{ action.since != null && action.until != null ? moment(action.since.date).format('H:mm')+' - '+moment(action.until.date).format('H:mm') : '' }</td>
        <td>
          { action.users? action.users.map((item, key) => {
            return <p key={ "item-"+key }>{ item.firstname+' '+item.lastname }</p>
          }) : null }
        </td>
        <td>
          <div className="buttons">

          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports, user } = state
  return { screen, reports, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(ReportItem));