import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { queueExport, listQueue, downloadReport } from '../../actions/actions';
import BaseComponent from "../Helpers";
import Button from "../Button";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import { getLocations, resetLocations} from '../../actions/locations';
import { getTerritories } from '../../actions/territories';
import { setDate, getPersonnel } from '../../actions/personnel';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';

class Export extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      since: moment(), 
      until: moment().add(7, 'days'),
    }
    //this._bind('clearFilter', 'setFilter');
  }

  componentDidMount() {
    this.props.listQueue()
  }

  render() {
    const sinceDate = this.state.since.toDate();
    const untilDate = this.state.until.toDate();
    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar"  onClick={onClick}/>
    );

    let queued = this.props.actions.queueData?.queued ? this.props.actions.queueData.queued : []
    if (queued.length > 0) {
      queued = queued.filter((item, key)=>{
        return item.isMine
      })
    }
    if (this.props.finished) {
      queued = queued.filter((item, key)=>{
        const isQueued = item.parameters.actions?.statuses?.idents.indexOf('finished')
        return isQueued >= 0 && isQueued != undefined ? true : false
      })
    }

    let available = this.props.actions.queueData?.finished ? this.props.actions.queueData.finished : []

    if (this.props.finished) { 
      available = available.filter((item, key)=>{
        const isFinished = item.parameters.actions?.statuses?.idents.indexOf('finished')
        return isFinished >= 0 && isFinished != undefined ? true : false
      })
    }

    return (
      <div className="export-wrap">
        <h3>Ataskaitų paketas</h3>
        <div className="column-row">
          <div className="date-row">
            <div className="since-date">
              <span>nuo </span>
              <label>{ this.state.since.format('YYYY.MM.DD') }</label>
              <DatePicker
                selected={sinceDate}
                locale="lt"
                popperPlacement="bottom-right"
                onChange={ (sinceDate) => {
                  let dt = moment(sinceDate);
                  this.setState({since: dt})
                } }
                customInput={<CalendarInput/>}
              />
            </div>
            <div className="since-date">
              <span>iki </span>
              <label>{ this.state.until.format('YYYY.MM.DD') }</label>
              <DatePicker
                selected={untilDate}
                locale="lt"
                popperPlacement="bottom-right"
                onChange={ (untilDate) => {
                  let dt = moment(untilDate);
                  this.setState({until: dt})
                } }
                customInput={<CalendarInput/>}
              />
            </div>
          </div>
          <div className="select-row">
            {/*<label htmlFor="filetype">Rodzaj pliku</label>
            <select id="filetype">
              <option value="xlsx">XLSX</option>
              </select>*/}
          </div>
        </div>
        
        <h3>Ataskaitos rengiamos</h3>
        <ul>
          { queued.map((item, key) => {
            return <li><div>{ `${item.parameters?.since} - ${item.parameters?.until}` }</div></li>
          })}
        </ul>
                     
        <div className="buttons">
          <Button type={ this.props.actions.queueInProgress ? 'inactive' : '' } action={ ()=>{
            if (!this.props.actions.queueInProgress) {
              this.props.queueExport('xlsx', this.state.since.format('YYYY-MM-DD'), this.state.until.format('YYYY-MM-DD'), this.props.finished ? true : false)} 
            }
          }>Generuoti</Button>
        </div>
        <h3>Ataskaitos paruoštos atsisiųsti</h3>
        <ul>
          { available?.map((item, key) => {
            return (
              <li>
                <div>{ `${item.parameters.since} - ${item.parameters.until}` } <br/><span className="small">Sukurta { moment(item.updated.date).format('YYYY.MM.DD HH:MM:ss')}</span></div>
                <div><Button type="empty" action={ ()=>{
                  this.props.downloadReport(item.uuid, item.parameters)
                }}>Parsisiųsti</Button></div>
              </li>
            )
          }) }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, personnel, user, events, locations, territories } = state
  return { screen, actions, personnel, user, events, locations, territories }
};

export default withRouter(connect(
  mapStateToProps,
  {
    getLocations,
    getTerritories,
    getPersonnel,
    resetLocations,
    setDate,
    queueExport,
    listQueue,
    downloadReport
  }
)(Export));