import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import moment from 'moment';
import 'moment/locale/lt';
import Button from '../Button';
import PopupModal from '../modals/PopupModal';
import _ from 'lodash';


class CalendarPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      availability: [{start: '09:00', end: '18:00'}],
      hourStart: 9,
      hourEnd: 16,
      selectedColumn: 0,
      popup: false,
      selectedMonth: moment().format("M"),
      dropdown: false
    };
    moment().locale('lt');
    this.hours = ['06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00']
    this._bind('setAvailability', 'openPopup')
  }

  componentWillMount() {
    const today = moment()
    const data = {
      "availability": {
          "since": "2020-" + today.format("M") + "-" + today.format("D"),
          "until": "2020-" + today.add(2, 'months').format("M") + "-" + today.add(2, 'months').daysInMonth()
      }
    }

    //this.props.uploadAvailability(data)
  }

  setAvailability() {
    const since = this.state.selectedColumn.set("hour", this.state.hourStart).format("YYYY-MM-DD HH:mm:ss")
    const until = this.state.selectedColumn.set("hour", this.state.hourEnd).format("YYYY-MM-DD HH:mm:ss")
    
    const data = {
      "availability": {
          "since": since,
          "until": until
      }
    }

    //this.props.createAvailability(data)
  }

  openPopup(hour, date) {
    this.setState({ 
      hourStart: moment('2020-10-10 '+hour).format('H'),
      hourEnd: moment('2020-10-10 '+hour).add(1,'hours').format('H'),
      selectedColumn: date,
      popup: true
    })
  }

  render() {
    const currentDay = moment().format("D")
    const currentMonth = moment().format("M")
    const currentMonthDays = moment().daysInMonth()
    const selectedMonthDays = this.state.selectedMonth !== currentMonth ? moment().set('month', this.state.selectedMonth-1).daysInMonth() : currentMonthDays
    let days = []
    let columns = []

    for (let i = currentMonth !== this.state.selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
      const day = moment( this.state.selectedMonth + "/" + i, 'MM/DD')

      const item = (
        <div key={ i } className={ i === currentDay ? "calendar-day today" : "calendar-day" }>
          <div className="day-name">{ day.format("dd") }</div><div className="day-no">{ day.format("D")  }</div>
        </div>
      )
      days.push(item)
    }

    for (let i = currentMonth !== this.state.selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
      const day = moment( this.state.selectedMonth + "/" + i, 'MM/DD')
      const availabilityItems = _.filter(this.props.person.availability, (item) => {
        if(moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
          return item
        }
      })

      const actionItems = _.filter(this.props.actions.list, (item) => {
        if(moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
          return item
        }
      })

      const item = (
        <div key={ i } className="calendar-column">
          { this.hours.map((item,key)=>{
            return <div key={ 'cell-'+key } className="hour-cell" ></div>
          })}
          { availabilityItems.map((avail, k) => {
            const diff = moment(avail.since.date).diff(moment(avail.until.date), 'hours');
            return <div className="availability" style={{ height: (-diff)*40-3, top: (((moment(avail.since.date).format("H")-6)*40)-3) + "px" }}><span>Dostępność</span></div>
          }) }
          { actionItems.map((action, k) => {
            const diff = moment(action.since.date).diff(moment(action.until.date), 'hours');
            return <div className="event" style={{ height: (-diff)*40-3, top: (((moment(action.since.date).format("H")-6)*40)-3) + "px" }}><span>{ action.name }</span></div>
          }) }
        </div>
      )
      columns.push(item)
    }

    return (
      <section className="calendar">
        <div className="calendar-hour-labels">
          { this.hours.map((item,key)=>{
            return <div key={ key } className="hour-label">{ item }</div>
          })}
        </div>
        <div className="calendar-columns">
          <div className="calendar-header">
            { days }
          </div>
          <div className="calendar-columns-inner">
            { columns }
          </div>
        </div>
        <PopupModal open={ this.state.popup } hidePopup={ ()=>{ this.setState({ popup: false }) } }>
          <div className="popup-inner">
            <h3>Įveskite pasiekiamumo sritį</h3>
            <div className="hours-inputs">
              <label>iš</label>
              <input type="number" min="6" max="22" onChange={ (e)=>{ this.setState({ hourStart: e.target.value }); } } value={ this.state.hourStart} />
              <label>žemyn</label>
              <input type="number" min="6" max="22" onChange={ (e)=>{ this.setState({ hourEnd: e.target.value }) } } value={ this.state.hourEnd} />
            </div>
            <Button action={ ()=>{ this.setState({ popup: false }); this.setAvailability(); } }>Paruošta</Button>
          </div>
        </PopupModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions } = state
  return { screen, actions }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(CalendarPage));