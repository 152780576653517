import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setFilter } from '../../actions/actions';
import BaseComponent from "../Helpers";
import Button from "../Button";
import _ from 'lodash';
import SearchInput, {createFilter} from 'react-search-input'
import SearchField from "../SearchField"
import "react-datepicker/dist/react-datepicker.css";
import { getLocations, resetLocations} from '../../actions/locations';
import { getTerritories } from '../../actions/territories';
import { setDate, getPersonnel } from '../../actions/personnel';

class Filter extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      cityFilter: this.props.actions.filter.cityFilter || '',
      cityFocus: false,
      streetFilter: this.props.actions.filter.streetFilter || '',
      streetFocus: false,
      idFilter: this.props.actions.filter.idFilter || '',
      idFocus: false,
      territoryFilter: this.props.actions.filter.territoryFilter || '',
      territoryFocus: false,
      territoryUUID: '',
      personnelFilter: this.props.actions.filter.personnelFilter || '',
      personnelFocus: false,
      personnelUUID: '',
      eventFilter: this.props.actions.filter.eventFilter || '',
      eventFocus: false,
      eventUUID: '',
      statusFilter: this.props.actions.filter.statusFilter || '',
      statusFocus: false
    }
    this._bind('clearFilter', 'setFilter');
  }

  setFilter() {
    this.props.setFilter({
      cityFilter: this.state.cityFilter, 
      streetFilter: this.state.streetFilter,
      idFilter: this.state.idFilter,
      territoryFilter: this.state.territoryFilter,
      territoryFilterUUID: this.state.territoryUUID,
      personnelFilter: this.state.personnelFilter,
      personnelFilterUUID: this.state.personnelUUID,
      eventFilter: this.state.eventUUID,
      eventFilterUUID: this.state.eventUUID,
      statusFilter: this.state.statusFilter
    });
    this.props.hide()
  }

  clearFilter() {
    this.setState({
      cityFilter: '',
      streetFilter: '',
      idFilter: '',
      territoryFilter: '',
      personnelFilter: '',
      eventFilter: '',
      statusFilter: ''
    })

    this.props.setFilter({
      cityFilter: '', 
      streetFilter: '',
      idFilter: '',
      territoryFilter: '',
      personnelFilter: '',
      eventFilter: '',
      statusFilter: ''
    });

  }

  render() {

    const filteredCities = this.props.actions.list.filter(createFilter(this.state.cityFilter, ['actionPoints.address.cityName']))
    const filteredStreets = this.props.actions.list.filter(createFilter(this.state.streetFilter, ['actionPoints.address.streetAddress']))
    const filteredIds = this.props.actions.list.filter(createFilter(this.state.idFilter, ['ident']))
    const filteredTerritories = this.props.actions.list.filter(createFilter(this.state.territoryFilter, ['territory.ident']))
    const filteredPersonnel = this.props.personnel.list.filter(createFilter(this.state.personnelFilter, ['firstname', 'lastname']))
    const filteredEvents = this.props.actions.list.filter(createFilter(this.state.eventFilter, ['event.name']))
    const filteredStatuses = this.props.actions.list.filter(createFilter(this.state.eventFilter, ['status.global.ident']))

    return (
      <div className="filter-wrap">
        <div className="filters">
          <div className="location-filters">
            <h3>Vieta</h3>
            <SearchField value={this.state.cityFilter} placeholder="Miestas" onChangeAction={(filter) => {this.setState({ cityFilter: filter })}}>
              <ul className={ this.state.cityFocus != '' ? 'active' : '' }>
                { _.uniqBy(filteredCities, 'actionPoints[0].address.cityName').map((item, i) => {
                  if (item.actionPoints.length > 0) {
                    return <li key={ i } onMouseDown={()=>{this.setState({cityFilter: item.actionPoints[0].address.cityName})}} >{ item.actionPoints[0].address.cityName }</li>
                  }
                }) }
              </ul>
            </SearchField>

            <SearchField value={this.state.streetFilter} placeholder="Gatvė" onChangeAction={(filter) => {this.setState({ streetFilter: filter })}}>
            <ul className={ this.state.streetFocus != '' ? 'active' : '' }>
                { _.uniqBy(filteredStreets, 'actionPoints[0].address.streetAddress').map((item, i) => {
                  if (item.actionPoints.length > 0) {
                    return <li key={ i } onMouseDown={()=>{this.setState({streetFilter: item.actionPoints[0].address.streetAddress})}} >{ item.actionPoints[0].address.streetAddress }</li>
                  }
                }) }
              </ul>
            </SearchField>

            <SearchField value={this.state.idFilter} placeholder="ID" onChangeAction={(filter) => {this.setState({ idFilter: filter }) }}>
              <ul className="search-list">
                { _.uniqBy(filteredIds, 'ident').map((item, i) => {
                  return <li key={ i } onMouseDown={()=>{this.setState({idFilter: item.ident})}} >{ item.ident }</li>
                }) }
              </ul>
            </SearchField>

            { this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ?
              <SearchField value={this.state.territoryFilter} placeholder="Teritorija" onChangeAction={(filter) => { this.setState({ territoryFilter: filter }) }}>
                <ul className={ this.state.territoryFocus != '' ? 'active' : '' }>
                  { _.uniqBy(filteredTerritories, 'territory.ident').map((item, i) => {
                    return <li key={ i } onMouseDown={()=>{this.setState({territoryFilter: item.territory.ident, territoryUUID: item.territory.uuid})}} >{ item.territory.ident }</li>
                  }) }
                </ul>
              </SearchField>
            : null }
          </div>
          <div className="utility-filters">
            <h3>Darbuotojas</h3>
            <SearchField value={this.state.personnelFilter} placeholder="Vardas ir pavardė" onChangeAction={ (filter) => { this.setState({ personnelFilter: filter }) }}>
              <ul className="search-list">
                { filteredPersonnel.map((item, i) => {
                  return <li key={ i } onMouseDown={()=>{this.setState({personnelFilter: item.firstname+' '+item.lastname, personnelUUID: item.uuid})}} >{ item.firstname+' '+item.lastname }</li>
                }) }
              </ul>
            </SearchField>
            <h3>Aktyvacijos statusas</h3>
            <SearchField value={this.state.statusFilter} placeholder="Statusas" onChangeAction={(filter) => { this.setState({ statusFilter: filter }) }}>
              <ul className={ this.state.statusFocus != '' ? 'active' : '' }>
                { _.uniqBy(filteredStatuses, 'status.global.ident').map((item, i) => {
                  return <li key={ i } onMouseDown={()=>{this.setState({statusFilter: item.status.global.ident})}} >{ item.status.global.ident }</li>
                }) }
              </ul>
            </SearchField>
            <h3>Veikla</h3>
            <SearchField value={this.state.eventFilter} placeholder="Veiklos pavadinimas" onChangeAction={(filter) => { this.setState({ eventFilter: filter }) }}>
              <ul className={ this.state.eventFocus != '' ? 'active' : '' }>
                { _.uniqBy(filteredEvents, 'event.name').map((item, i) => {
                  return <li key={ i } onMouseDown={()=>{this.setState({eventFilter: item.event.name, eventUUID: item.event.uuid})}} >{ item.event.name }</li>
                }) }
              </ul>
            </SearchField>
          </div>
        </div>
        <div className="buttons">
          <Button type="orange" action={ this.clearFilter }>Nustatyti iš naujo</Button>
          <Button action={ this.setFilter }>Taikyti filtrą</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, personnel, user, events, locations, territories } = state
  return { screen, actions, personnel, user, events, locations, territories }
};

export default withRouter(connect(
  mapStateToProps,
  {
    getLocations,
    getTerritories,
    getPersonnel,
    resetLocations,
    setDate,
    setFilter
  }
)(Filter));