import {
  UPLOAD_REPORTS
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case UPLOAD_REPORTS:
      return {
        ...state,
        list: action.payload.actions,
        count: action.payload.count,
        maxPage: Math.ceil(action.payload.count/100)
      }

    default:
      return state;
  }
}